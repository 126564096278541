import { combineReducers } from '@reduxjs/toolkit';
import { reducer as notifierReducer } from '../slices/notifier';
import { reducer as notificationReducer } from '../slices/notification';
import { reducer as modalsReducer } from '../slices/modals';
import { reducer as userReducer } from '../slices/user';
import { reducer as diverseReducer } from '../slices/diverse';
import { reducer as switchReducer } from '../slices/switch';
import { reducer as athReducer } from '../slices/auth';
import { reducer as childrenReducer } from '../slices/children';
import { reducer as eventsReducer } from '../slices/events';
import { reducer as examsReducer } from '../slices/exams';
import { reducer as subjectsReducer } from '../slices/subjects';
import { reducer as chaptersReducer } from '../slices/chapters';
import { reducer as liveSessionReducer } from '../slices/liveSession';
import { reducer as progressReducer } from '../slices/progress';
import { reducer as documentReducer } from '../slices/document';
import { reducer as pointsReducer } from '../slices/points';
import { reducer as placesReducer } from '../slices/places';

const combinedReducer = combineReducers({
  notifier: notifierReducer,
  notifications: notificationReducer,
  modals: modalsReducer,
  user: userReducer,
  diverse: diverseReducer,
  switch: switchReducer,
  auth: athReducer,
  children: childrenReducer,
  events: eventsReducer,
  exams: examsReducer,
  subjects: subjectsReducer,
  chapters: chaptersReducer,
  liveSession: liveSessionReducer,
  progress: progressReducer,
  document: documentReducer,
  points: pointsReducer,
  places: placesReducer,
});

const rootReducer = (state, action) => {
  if (action.type === 'LOGOUT') {
    // check for action type
    state = undefined;
  }
  return combinedReducer(state, action);
};

export default rootReducer;
