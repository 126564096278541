import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import { Typography } from '@material-ui/core';
import addPointsIcon from '../../assets/img/icons/addPointsIcon.png';
//import Modal from '../../components/Modal/Modal';

const Index = (props) => {
  const { title, onClick } = props;

  return (
    <>
      <div className="point-transfer" onClick={onClick}>
        <img alt="addPointsIcon" src={addPointsIcon} className="addPointsIcon" />
        <Typography className="transferText">{title}</Typography>
      </div>
    </>
  );
};

Index.propTypes = {
  open: PropTypes.bool,
  openModal: PropTypes.func,
  openTransferModal: PropTypes.func,
  onCloseModal: PropTypes.func,
};

export default Index;
