import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import useIsMountedRef from '../../hooks/useIsMountedRef';
import React from 'react';
import Button from '@material-ui/core/Button';
import DialogContentText from '@material-ui/core/DialogContentText';
import { useTheme } from '@material-ui/core/styles';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { useSelector, useDispatch } from 'react-redux';
import CircularProgress from '@material-ui/core/CircularProgress';
import CheckCircleOutlineIcon from '@material-ui/icons/CheckCircleOutline';
import { checkQrCode } from '../../slices/points';

const QrCode = ({ id, open = true, handleClose, data, ...rest }) => {
  const { points, QrCode, QrCodeCheck } = useSelector((state) => state.points);
  const theme = useTheme();

  const isMountedRef = useIsMountedRef();

  const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));
  const dispatch = useDispatch();

  const codeId = points && points.id;

  const handleCheck = () => {
    dispatch(checkQrCode(codeId));
  };

  return (
    <Dialog
      open={open}
      onClose={(e, reason) => {
        if (reason !== 'backdropClick' && reason !== 'escapeKeyDown') {
          handleClose(id);
        }
      }}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      className="ta-modal add-video-modal"
    >
      <DialogTitle id="alert-dialog-title">
        <span className="label">QR Code</span>
        <span className="close-btn" onClick={() => handleClose(id)}>
          Fermer
        </span>
      </DialogTitle>
      <DialogContent className="ta-modal-content-scroll">
        <div className="d17">
          <div className="group-text-D17">
            <img src={QrCode?.code.urlQrCode} class="image-modal" />
            <DialogContentText className="text-modal-D17">
              {QrCodeCheck.status === 'loading' && !QrCodeCheck.codeCheck ? (
                <CircularProgress style={{ color: '#50bf88', height: '70px', width: '70px' }} />
              ) : QrCodeCheck.error ? (
                <span style={{ color: '#ec5542' }}>{QrCodeCheck.error} </span>
              ) : QrCodeCheck.codeCheck ? (
                <CheckCircleOutlineIcon
                  style={{ color: '#50bf88', height: '100px', width: '100px' }}
                />
              ) : (
                <span>
                  1- Scanner ce QR code avec l'application D17.
                  <br />
                  2- Ensuite appuyer sur continuer.{' '}
                </span>
              )}
            </DialogContentText>
            <div className="Qr-button">
              {!QrCodeCheck.status !== 'loading' && (
                <Button
                  disabled={
                    QrCodeCheck.status === 'loading' || QrCodeCheck.codeCheck ? true : false
                  }
                  onClick={handleCheck}
                  className="ta-btn primary btn-rounded  "
                >
                  Continuer
                </Button>
              )}
              {(QrCodeCheck.codeCheck || QrCodeCheck.error) && (
                <Button onClick={() => handleClose(id)} className="ta-btn primary btn-rounded">
                  fermer{' '}
                </Button>
              )}
            </div>
          </div>
        </div>
      </DialogContent>
    </Dialog>
  );
};

export default QrCode;
