import React, { useEffect, useLayoutEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import TablePagination from '@material-ui/core/TablePagination';
import * as dayjs from 'dayjs';
import { convertSecondDuration } from '../../utilities/timeMethods';
import TableSortLabel from '@material-ui/core/TableSortLabel';
import KeyboardArrowUpIcon from '@material-ui/icons/KeyboardArrowUp';
import FormatListBulletedOutlinedIcon from '@material-ui/icons/FormatListBulletedOutlined';
import history from '../../assets/img/icons/history.png';
import { Empty } from '../../components';
import ExpandRow from './ExpandRow';
import ActionsRow from './ActionsRow';
import { Checkbox, IconButton } from '@material-ui/core';
import { fetchOneEvent } from '../../slices/events';
import { useDispatch, useSelector } from 'react-redux';
import CheckCircleOutlineIcon from '@material-ui/icons/CheckCircleOutline';
import {
  AddCircleOutline,
  CheckCircle,
  PauseCircleFilled,
  RadioButtonUncheckedOutlined,
} from '@material-ui/icons';
import { openModal } from '../../slices/modals';

const TransactionsResponsiveTable = ({
  cols,
  rows,
  bookmark,
  emptyComponent,
  setRows,
  isSessionTable,
  sort,
  handleSort,
  sortName,
  rowsCount,
  events,
  noPagination,
  className,
}) => {
  const { user } = useSelector((state) => state.user);
  const respoContEl = useRef(null);
  const tableContEl = useRef(null);
  const paginContEl = useRef(null);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [selectedIndex, setSelectedIndex] = useState('');
  const [eventId, setEventId] = useState(null);
  const { selectedChild } = useSelector((state) => state.children);
  const { event } = useSelector((state) => state.events);

  const [tableContHeight, setTableContHeight] = useState(0);
  const [paginContHeight, setPaginContHeight] = useState(0);
  const userId = user && user.id;
  const dispatch = useDispatch();
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRows(+event.target.value);
    setRowsPerPage(+event.target.value);
    setPage(0);
  };
  const updateTableDemonsions = (taH, PaH) => {
    respoContEl.current.style.height = `${taH + PaH - 16}px`;
    if (!noPagination) {
      return (paginContEl.current.style.marginTop = `${taH}px`);
    } else {
      return `5px`;
    }
  };
  useLayoutEffect(() => {
    setTableContHeight(tableContEl.current.clientHeight);
    setPaginContHeight(!noPagination ? paginContEl.current.clientHeight : 0);
    updateTableDemonsions(tableContHeight, paginContHeight);
  });

  const handleConfirmTransfer = (id) => {
    dispatch(openModal('confirm-transfer-point-modal', { id }));
  };
  const handleSelected = (index) => {
    setSelectedIndex((prev) => {
      return { ...prev, selectedIndex: prev.selectedIndex == index ? null : index };
    });
  };
  const handleEventId = (eventId) => {
    setEventId(eventId);
  };
  useEffect(() => {
    if (eventId != null) {
      dispatch(fetchOneEvent({ selectedChild, eventId }));
    }
  }, [eventId]);
  return (
    <div className={`table-responsive ${className}`} ref={respoContEl}>
      <TableContainer className="table-container" ref={tableContEl} component={Paper}>
        <Table stickyHeader aria-label="sticky table">
          <TableHead>
            <TableRow>
              {cols &&
                cols.map((header, index) => {
                  return (
                    <TableCell key={index + 1}>
                      {isSessionTable ? (
                        <TableSortLabel
                          active={sortName === header.accessor}
                          direction={sort}
                          onClick={() => handleSort(header.accessor, sort)}
                        >
                          {header.label}
                        </TableSortLabel>
                      ) : (
                        header.label
                      )}
                    </TableCell>
                  );
                })}
            </TableRow>
          </TableHead>
          <TableBody>
            {rows && rows.length > 0 ? (
              rows.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((row, index) => {
                return (
                  <ExpandRow
                    indexSelected={selectedIndex.selectedIndex === index}
                    expandComponent={
                      <TableCell colSpan="5">
                        <ActionsRow data={event || {}} />
                      </TableCell>
                    }
                    key={index}
                  >
                    {cols &&
                      cols.length > 0 &&
                      cols.map((col, index2) => {
                        return col.accessor === 'status' ? (
                          <TableCell key="5">
                            <div>
                              {parseInt(row[col.accessor]) === userId ? 'Transfert' : 'Réçu'}
                            </div>
                          </TableCell>
                        ) : col.accessor === 'is_confirmed' ? (
                          <TableCell key="5">
                            <div className="title">
                              <Checkbox
                                disabled={parseInt(row.status) !== userId}
                                style={{
                                  color: '#fe726e',
                                }}
                                onClick={() => handleConfirmTransfer(parseInt(row.id))}
                                checked={row['is_confirmed']}
                                className="button-check"
                                icon={<RadioButtonUncheckedOutlined />}
                                checkedIcon={<CheckCircle />}
                              />
                              <span
                                className="label"
                                style={{
                                  color: row['is_confirmed'] && 'gray',
                                  textDecoration: row['is_confirmed'] && 'line-through',
                                }}
                              >
                                {!row['is_confirmed'] && parseInt(row.status) === userId
                                  ? 'Confirmer action'
                                  : row['is_confirmed']
                                  ? 'Action confirmée'
                                  : 'En attente'}
                              </span>
                            </div>
                          </TableCell>
                        ) : (
                          <TableCell key={index2}>
                            <div>
                              {col.type && col.type === 'date' ? (
                                dayjs(row[col.accessor]).format('DD/MM/YYYY HH:mm')
                              ) : col.type === 'time' ? (
                                convertSecondDuration(row[col.accessor])
                              ) : col.type === 'collapse' ? (
                                <IconButton
                                  onClick={() => {
                                    handleSelected(index);
                                    handleEventId(rows[index].contentEventId);
                                  }}
                                >
                                  {index === selectedIndex.selectedIndex ? (
                                    <KeyboardArrowUpIcon />
                                  ) : (
                                    <FormatListBulletedOutlinedIcon />
                                  )}
                                </IconButton>
                              ) : (
                                row[col.accessor]
                              )}
                            </div>
                          </TableCell>
                        );
                      })}
                  </ExpandRow>
                );
              })
            ) : (
              <TableRow hover role="checkbox" tabIndex={-1}>
                <TableCell colSpan={(cols && cols.length) || 1} align={'center'}>
                  {
                    (emptyComponent = (
                      <Empty label="Pas d'historique." icon={history} className="no-borders" />
                    ))
                  }
                </TableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>
      </TableContainer>
      {!noPagination && (
        <TablePagination
          ref={paginContEl}
          rowsPerPageOptions={[10, 25, 100]}
          component="div"
          count={rows?.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onChangeRowsPerPage={handleChangeRowsPerPage}
          labelRowsPerPage={<span>Lignes par page:</span>}
          labelDisplayedRows={({ from, to, count }) => {
            return '' + from + '-' + to + ' sur ' + count;
          }}
        />
      )}
    </div>
  );
};

TransactionsResponsiveTable.propTypes = {
  page: PropTypes.number,
  setPage: PropTypes.func,
  rowsPerPage: PropTypes.number,
  setRowsPerPage: PropTypes.func,
  handleChangePage: PropTypes.func,
  handleChangeRowsPerPage: PropTypes.func,
};

export default TransactionsResponsiveTable;
