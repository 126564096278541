import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import dayjs from 'dayjs';
import axios from '../utilities/axios';
import { ord } from '../utilities/methods';
///import { enqueueSnackbar } from './notifier';
//import { closeModal } from './modals';
//import Slide from '@material-ui/core/Slide';

const initialState = {
  event: null,
  events: [],
  today_events: null,
  last_request_at: null,
  status: 'idle',
  error: null,
};

export const fetchEvents = createAsyncThunk(
  'events/fetchEvents',
  async ({ selectedChild, offset }) => {
    let data;

    try {
      const response = await axios.get(`/parent/${selectedChild}/events?offset=${offset}`);
      data = await response.data;
      if (response.status === 200) {
        return data.payload;
      }
      throw new Error(response.statusText);
    } catch (err) {
      console.log(err);
      return Promise.reject(err.message ? err.message : data?.message);
    }
  }
);
export const fetchOneEvent = createAsyncThunk(
  'events/fetchOneEvents',
  async ({ selectedChild, eventId }) => {
    let data;

    try {
      const response = await axios.get(`/parent/${selectedChild}/events/${eventId}`);
      data = await response.data;
      if (response.status === 200) {
        return data.payload;
      }
      throw new Error(response.statusText);
    } catch (err) {
      console.log(err);
      return Promise.reject(err.message ? err.message : data?.message);
    }
  }
);
export const fetchTodayEvents = createAsyncThunk(
  'events/fetchTodayEvents',
  async ({ selectedChild }) => {
    let data;

    try {
      const response = await axios.get(`/parent/${selectedChild}/events?offset=0&today=1`);
      data = await response.data;
      if (response.status === 200) {
        return data.payload;
      }
      throw new Error(response.statusText);
    } catch (err) {
      console.log(err);
      return Promise.reject(err.message ? err.message : data?.message);
    }
  }
);

const slice = createSlice({
  name: 'events',
  initialState,
  reducers: {},
  extraReducers: {
    [fetchEvents.pending]: (state, action) => {
      state.status = 'loading';
    },
    [fetchEvents.fulfilled]: (state, action) => {
      state.status = 'succeeded';
      if (action?.payload && action?.payload?.length > 0) {
        const newData = action.payload.map((item) => {
          return {
            ...item,
            start_date: item.event.start_time,
            end_date: item.event.end_time,
          };
        });
        state.events = newData;
      } else {
        state.events = action.payload;
      }
    },
    [fetchEvents.rejected]: (state, action) => {
      state.status = 'failed';
      state.error = action.payload;
    },
    [fetchTodayEvents.pending]: (state, action) => {
      state.status = 'loading';
    },
    [fetchTodayEvents.fulfilled]: (state, action) => {
      state.status = 'succeeded';
      let today_events = action?.payload?.events || [];

      const options = [
        { label: 'id', accessor: 'id' },
        { label: 'description', accessor: 'description' },
        { label: 'subject', accessor: 'subject.name' },
        { label: 'teacher', accessor: 'teacher' },
        { label: 'start_time', accessor: 'event.start_time' },
      ];
      state.today_events = ord(today_events, options);

      state.last_request_at = dayjs();
    },
    [fetchTodayEvents.rejected]: (state, action) => {
      state.status = 'failed';
      state.error = action.payload;
    },
    [fetchOneEvent.pending]: (state, action) => {
      state.status = 'loading';
    },
    [fetchOneEvent.fulfilled]: (state, action) => {
      state.status = 'succeeded';
      state.event = action.payload;
    },

    [fetchOneEvent.rejected]: (state, action) => {
      state.status = 'failed';
      state.error = action.payload;
    },
  },
});

export const reducer = slice.reducer;

export default slice;
