import React from 'react';
import Card from '@material-ui/core/Card';
import CardHeader from '@material-ui/core/CardHeader';
import CardContent from '@material-ui/core/CardContent';
import {
  CardTitle,
  PaymentMethodsList,
  PointsTransferButton,
  TransferLink,
} from '../../components';
import { useDispatch } from 'react-redux';
import { openModal } from '../../slices/modals';

const Index = () => {
  const dispatch = useDispatch();
  return (
    <Card className="ta-card wallet-services">
      <CardHeader title={<CardTitle title="Services" />} />
      <CardContent>
        <div className="child-full custom-services-child">
          <CardTitle title="Charger mon compte" className="dark-blue" />
          <PaymentMethodsList />
          <PointsTransferButton
            title={'Ajouter des points'}
            onClick={() => dispatch(openModal('add-point-modal', { name: 'Ajouter des points' }))}
          />
        </div>
      </CardContent>
    </Card>
  );
};

export default Index;
