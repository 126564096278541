import { IconButton, TableCell, TableRow } from '@material-ui/core';
import React, { useState } from 'react';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@material-ui/icons/KeyboardArrowUp';

const ExpandRow = ({ indexSelected, children, expandComponent, ...otherProps }) => {
  return (
    <>
      <TableRow {...otherProps}>{children}</TableRow>
      {indexSelected && <TableRow>{expandComponent}</TableRow>}
    </>
  );
};
export default ExpandRow;
