import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import Axios from '../utilities/axios';
import axios from 'axios';
import Cookies from 'js-cookie';

const initialState = {
  status: 'idle',
  error: null,
  geoIp: null,
  states: null,
};

export const getStates = createAsyncThunk('places/getStates', async () => {
  let data;
  try {
    const response = await Axios.get(`/countries/1/states`);
    data = await response.data;
    if (response.status === 200) {
      return data.payload;
    }
    throw new Error(response.statusText);
  } catch (err) {
    console.log(err);
    return Promise.reject(err.message ? err.message : data?.message);
  }
});

export const getGeoInfo = createAsyncThunk('places/getGeoInfo', async () => {
  let data;
  try {
    const response = await axios.get(`https://geoip.softylines.com/api/geolocation`);
    data = await response.data;
    if (response.status === 200) {
      return data;
    }
    throw new Error(response.statusText);
  } catch (err) {
    console.log(err);
    return Promise.reject(err.message ? err.message : data?.message);
  }
});
export const getCountries = createAsyncThunk('places/getCountries', async () => {
  let data;
  try {
    const response = await Axios.get(`/countries`);
    data = await response.data;
    if (response.status === 200) {
      return data.payload;
    }
    throw new Error(response.statusText);
  } catch (err) {
    console.log(err);
    return Promise.reject(err.message ? err.message : data?.message);
  }
});

const slice = createSlice({
  name: 'places',
  initialState,
  reducers: {},
  extraReducers: {
    [getStates.pending]: (state, action) => {
      state.status = 'loading';
    },
    [getStates.fulfilled]: (state, action) => {
      state.status = 'succeeded';
      state.states = action.payload;
    },
    [getStates.rejected]: (state, action) => {
      state.status = 'failed';
      state.error = action.payload;
    },
    [getGeoInfo.pending]: (state, action) => {
      state.status = 'loading';
    },
    [getGeoInfo.fulfilled]: (state, action) => {
      state.status = 'succeeded';
      state.geoIp = action.payload;
      Cookies.set('GeoIp', action.payload.country);
    },
    [getGeoInfo.rejected]: (state, action) => {
      state.status = 'failed';
      state.error = action.payload;
    },
    [getCountries.pending]: (state, action) => {
      state.status = 'loading';
    },
    [getCountries.fulfilled]: (state, action) => {
      state.status = 'succeeded';
      state.countries = action.payload;
    },
    [getCountries.rejected]: (state, action) => {
      state.status = 'failed';
      state.error = action.payload;
    },
  },
});

export const reducer = slice.reducer;

export default slice;
