import React, { useEffect } from 'react';
import { Formik } from 'formik';
import * as Yup from 'yup';
import Button from '@material-ui/core/Button';
import {
  TextField,
  Box,
  FormHelperText,
  Typography,
  useMediaQuery,
  useTheme,
  makeStyles,
} from '@material-ui/core';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import useIsMountedRef from '../../hooks/useIsMountedRef';
import { Autocomplete, ToggleButton, ToggleButtonGroup } from '@material-ui/lab';
import { addExistedChild, addNewChild } from '../../slices/children';
import { fetchAllDivision, fetchAllState } from '../../slices/diverse';
import { useDispatch, useSelector } from 'react-redux';

const useStyles = makeStyles(() => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
  },
  input: {
    '&.Mui-focused': {
      zIndex: 0,
      border: '2px solid #2ba7df',
      '& .MuiOutlinedInput-notchedOutline': {
        border: 'none',
      },
    },
  },
}));

const AddChild = ({ id, open, handleClose, data, ...rest }) => {
  const isMountedRef = useIsMountedRef();
  const classes = useStyles();
  const dispatch = useDispatch();
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down('xs'));
  const [alignment, setAlignment] = React.useState('Oui');
  const { allDivisions, allStates } = useSelector((state) => state.diverse);

  useEffect(() => {
    if (allDivisions.status === 'idle' && open === true) {
      dispatch(fetchAllDivision());
    }
    if (allStates.status === 'idle' && open === true) {
      dispatch(fetchAllState());
    }
  }, [open, allDivisions.status, dispatch]);

  const handleAlignment = (event, newAlignment) => {
    if (newAlignment !== null) {
      setAlignment(newAlignment);
    }
  };
  return (
    <Dialog
      open={open}
      onClose={(e, reason) => {
        if (reason !== 'backdropClick' && reason !== 'escapeKeyDown') {
          handleClose(id);
        }
      }}
      fullScreen={fullScreen}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      className="ta-modal add-child-modal"
    >
      <DialogTitle id="alert-dialog-title">
        <span className="label">Ajouter un enfant</span>
        <span className="close-btn" onClick={() => handleClose(id)}>
          Fermer
        </span>
      </DialogTitle>
      <DialogContent className="ta-modal-content-scroll">
        <Formik
          initialValues={{
            id: '',
            name: '',
            lastName: '',
            phone: '',
            email: '',
            birthDate: '',
            password: '',
            passwordConfirmation: '',
            state: '',
            country: '',
            division: '',
            optionalSubject: '',
          }}
          validationSchema={
            alignment == 'Non'
              ? Yup.object().shape({
                  name: Yup.string().max(255).required('Le nom est requis'),
                  lastName: Yup.string().max(255).required('Le Prénom est requis'),
                  phone: Yup.string().min(8).max(8).required('Numéro de téléphone est requis'),
                  password: Yup.string()
                    .min(8, 'Mot de passe doit être au moins 8 caractères')
                    .required('Le mot de passe est requis'),
                  passwordConfirmation: Yup.string()
                    .min(8, 'Mot de passe doit être au moins 8 caractères')
                    .oneOf([Yup.ref('password'), null], 'Les mots de passe doivent correspondre'),
                  email: Yup.string().max(255).required('Email est requis'),
                })
              : Yup.object().shape({
                  id: Yup.number().required('Identifiant est requis'),
                })
          }
          onSubmit={async (values, { setErrors, setStatus, setSubmitting }) => {
            try {
              let dataToSend = {
                name: values.name,
                lastName: values.lastName,
                phone: values.phone,
                email: values.email,
                birthDate: values.birthdate,
                plainPassword: {
                  first: values.password,
                  second: values.passwordConfirmation,
                },
                state: values.state.id,
                country: values.country,
                division: values.division.id,
                optionalSubject: values.optionalSubject,
              };

              if (alignment == 'Oui') {
                await dispatch(addExistedChild({ userId: values.id }));
              }
              if (alignment == 'Non') {
                await dispatch(addNewChild({ values: dataToSend }));
              }

              if (isMountedRef.current) {
                setStatus({ success: true });
                setSubmitting(false);
              }
            } catch (err) {
              console.error(err);
              if (isMountedRef.current) {
                setStatus({ success: false });
                setErrors({ submit: err.message });
                setSubmitting(false);
              }
            }
          }}
        >
          {({
            errors,
            handleBlur,
            handleChange,
            handleSubmit,
            isSubmitting,
            touched,
            values,
            resetForm,
            setFieldValue,
            setTouched,
          }) => (
            <form noValidate onSubmit={handleSubmit} className="ta-form" {...rest}>
              <div className="toggel-bt-container">
                <span className="bt-title-add-child">
                  Votre enfant est-il inscrit sur le site ?
                </span>
                <ToggleButtonGroup
                  value={alignment}
                  exclusive
                  onChange={handleAlignment}
                  aria-label="text alignment"
                >
                  <ToggleButton value="Oui" aria-label="left aligned">
                    Oui
                  </ToggleButton>
                  <ToggleButton value="Non" aria-label="right aligned">
                    Non
                  </ToggleButton>
                </ToggleButtonGroup>
              </div>
              {alignment == 'Oui' && (
                <TextField
                  className="input m-size"
                  error={Boolean(touched.id && errors.id)}
                  helperText={touched.id && errors.id}
                  // label="Title"
                  name="id"
                  onBlur={handleBlur}
                  onChange={handleChange}
                  type="number"
                  value={values.id}
                  variant="outlined"
                  size="small"
                  placeholder="Ajouter l'identifiant de votre enfant (ID)"
                  InputProps={{
                    className: classes.input,
                  }}
                />
              )}
              {alignment == 'Non' && (
                <Box className="ta-information">
                  <Box mt={2} className="ta-name">
                    <TextField
                      className="input m-size"
                      error={Boolean(touched.name && errors.name)}
                      helperText={touched.name && errors.name}
                      // label="Title"
                      name="name"
                      onBlur={handleBlur}
                      onChange={handleChange}
                      type="text"
                      value={values.name}
                      variant="outlined"
                      size="small"
                      placeholder="Nom"
                      InputProps={{
                        className: classes.input,
                      }}
                    />
                    <TextField
                      className="input m-size"
                      helperText={touched.lastName && errors.lastName}
                      error={Boolean(touched.lastName && errors.lastName)}
                      // label="Title"
                      name="lastName"
                      onBlur={handleBlur}
                      onChange={handleChange}
                      type="text"
                      value={values.lastName}
                      variant="outlined"
                      size="small"
                      placeholder="Prénom"
                      InputProps={{
                        className: classes.input,
                      }}
                    />
                  </Box>

                  <TextField
                    className="input m-size"
                    error={Boolean(touched.phone && errors.phone)}
                    size="small"
                    fullWidth
                    helperText={touched.phone && errors.phone}
                    // label="Title"
                    margin="dense"
                    name="phone"
                    onBlur={handleBlur}
                    onChange={handleChange}
                    type="text"
                    value={values.phone}
                    variant="outlined"
                    placeholder="Phone"
                    InputProps={{
                      className: classes.input,
                    }}
                  />
                  <TextField
                    className="input m-size"
                    error={Boolean(touched.email && errors.email)}
                    fullWidth
                    helperText={touched.email && errors.email}
                    // label="Title"
                    margin="dense"
                    name="email"
                    onBlur={handleBlur}
                    onChange={handleChange}
                    type="emai"
                    value={values.email}
                    variant="outlined"
                    size="small"
                    placeholder="Email"
                    InputProps={{
                      className: classes.input,
                    }}
                  />
                  <TextField
                    className="input m-size"
                    error={Boolean(touched.birthDate && errors.birthDate)}
                    fullWidth
                    helperText={touched.birthDate && errors.birthDate}
                    // label="Title"
                    margin="dense"
                    name="birthDate"
                    onBlur={handleBlur}
                    onChange={handleChange}
                    type="date"
                    value={values.birthDate}
                    variant="outlined"
                    size="small"
                    placeholder="Mot de passe"
                    InputProps={{
                      className: classes.input,
                    }}
                  />
                  <TextField
                    className="input m-size"
                    error={Boolean(touched.password && errors.password)}
                    fullWidth
                    helperText={touched.password && errors.password}
                    // label="Title"
                    margin="dense"
                    name="password"
                    onBlur={handleBlur}
                    onChange={handleChange}
                    type="password"
                    value={values.password}
                    variant="outlined"
                    size="small"
                    placeholder="Mot de passe"
                    InputProps={{
                      className: classes.input,
                    }}
                  />
                  <TextField
                    className="input m-size"
                    error={Boolean(touched.passwordConfirmation && errors.passwordConfirmation)}
                    helperText={touched.passwordConfirmation && errors.passwordConfirmation}
                    fullWidth
                    margin="dense"
                    name="passwordConfirmation"
                    onBlur={handleBlur}
                    onChange={handleChange}
                    type="password"
                    value={values.confirmationPassword}
                    variant="outlined"
                    size="small"
                    placeholder="Confirmation mot de passe"
                    InputProps={{
                      className: classes.input,
                    }}
                  />
                  <Autocomplete
                    className="input m-size"
                    name="optionalSubject"
                    onBlur={() => setTouched({ division: true })}
                    //disabled={data?.external != null}
                    fullWidth
                    size="small"
                    onChange={(e, value) => {
                      setFieldValue('division', value);
                    }}
                    getOptionSelected={(option, value) => option.name === value.name}
                    options={allDivisions?.data}
                    value={values?.division}
                    getOptionLabel={(option) => (option ? option?.name : '')}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        className="input m-size "
                        variant="outlined"
                        placeholder="Classe"
                        margin="dense"
                        // InputProps={{
                        //   className: classes.input,
                        // }}

                        //  helperText={touched.division && errors.division}
                        //   error={Boolean(touched.division && errors.division)}
                      />
                    )}
                  />
                  <Autocomplete
                    className="input m-size"
                    name="State"
                    onBlur={() => setTouched({ state: true })}
                    fullWidth
                    size="small"
                    onChange={(e, value) => setFieldValue('state', value)}
                    getOptionSelected={(option, value) => option?.name === value?.name}
                    options={allStates?.data}
                    value={values?.state}
                    getOptionLabel={(option) => (option ? option?.name : '')}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        className="input m-size "
                        variant="outlined"
                        placeholder="state"
                        margin="dense"
                        // InputProps={{
                        //   className: classes.input,
                        // }}

                        //  helperText={touched.teachers && errors.teachers}
                        //   error={Boolean(touched.teachers && errors.teachers)}
                      />
                    )}
                  />
                </Box>
              )}
              <Box mt={2} className="ta-btns-group">
                <Button
                  disabled={isSubmitting}
                  type="reset"
                  className="ta-btn btn-rounded btn-lg blue"
                  onClick={() => handleClose(id)}
                >
                  fermer
                </Button>
                <Button
                  disabled={isSubmitting}
                  type="submit"
                  className="ta-btn primary btn-rounded btn-lg "
                >
                  Ajouter
                </Button>
              </Box>
            </form>
          )}
        </Formik>
      </DialogContent>
    </Dialog>
  );
};

export default AddChild;
