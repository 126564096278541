import Im_Accueil from '../assets/img/icons/home.svg';

import Im_Facebook from '../assets/img/icons/facebook.svg';
import Im_Instagram from '../assets/img/icons/instagram.svg';
import Im_Youtube from '../assets/img/icons/youtube.svg';
import Im_Videos from '../assets/img/icons/video.svg';
import Im_MoneyBag from '../assets/img/icons/money-bag.svg';
import Im_D17 from '../assets/img/icons/d17.png';
import Im_CreditCard from '../assets/img/icons/credit-card.svg';
import Im_Wallet from '../assets/img/icons/wallet.svg';
import Im_Exams from '../assets/img/icons/exams.svg';
import Im_Tasks from '../assets/img/icons/tasks.svg';
import Im_Matieres from '../assets/img/icons/book.svg';

export default {
  HEADERS_API: {
    'Content-Type': 'application/json',
    'Accept': 'application/json',
  },

  DRAWER_WIDTH: 280,
  SIDEBAR_LIST: [
    {
      id: 1,
      icon: Im_Accueil,
      name: 'Accueil',
      url: '/',
      type: 'route',
      permission: false,
    },
    {
      id: 2,
      icon: Im_Videos,
      name: 'En direct',
      url: '/en-direct',
      type: 'route',
      permission: false,
    },
    {
      id: 3,
      icon: Im_Matieres,
      name: 'Matières',
      url: '/subjects',
      type: 'route',
      permission: false,
    },
    {
      id: 4,
      icon: Im_Tasks,
      name: 'Travail à faire',
      url: 'travail-à-faire',
      type: 'route',
      permission: false,
    },
    {
      id: 5,
      icon: Im_Exams,
      name: 'Examens',
      url: '/examens',
      type: 'route',
      permission: false,
    },
    {
      id: 6,
      icon: Im_Wallet,
      name: 'Porte monnaie',
      url: 'porte-monnaie',
      type: 'route',
      permission: false,
    },
  ],

  SOCIALS_LIST: [
    {
      name: 'Facebook',
      img: Im_Facebook,
      url: 'https://www.facebook.com/takiacademy',
    },
    {
      name: 'Instagram',
      img: Im_Instagram,
      url: 'https://www.instagram.com/takiacademy',
    },
    {
      name: 'Youtube',
      img: Im_Youtube,
      url: 'https://www.youtube.com/takiacademy',
    },
  ],
  BANK_ACCOUNTS: [
    {
      name: 'Banque zitouna',
      details: [
        {
          owner: 'TakiAcademy',
          number: '25 006 0000000317041 86',
        },
      ],
    },
    {
      name: 'Banque biat',
      details: [
        {
          owner: 'TakiAcademy',
          number: '08 139 0310110000790 88',
        },
      ],
    },
    {
      name: 'La poste tunisienne',
      details: [
        {
          owner: null,
          number: '5359 4017 2354 0098',
        },
      ],
    },
  ],
  PAYMENT_METHODS: [
    {
      id: 'transfer',
      name: 'Versement bancaire',
      img: Im_MoneyBag,
      color: 'color-1',
      modal: null,
    },
    {
      id: 'd17',
      name: 'D17',
      img: Im_D17,
      color: 'color-2',
      modal: null,
    },
    {
      id: 'online',
      name: 'Paiement en ligne',
      img: Im_CreditCard,
      color: 'color-3',
      modal: null,
    },
  ],
  months: [
    { label: 'Jan', value: 'January' },
    { label: 'Feb', value: 'February' },
    { label: 'Mar', value: 'March' },
    { label: 'Apr', value: 'April' },
    { label: 'May', value: 'May' },
    { label: 'Jun', value: 'June' },
    { label: 'Jul', value: 'July' },
    { label: 'Aug', value: 'August' },
    { label: 'Sep', value: 'September' },
    { label: 'Oct', value: 'October' },
    { label: 'Nov', value: 'November' },
    { label: 'Dec', value: 'December' },
  ],
};
