import React, { Suspense, Fragment, lazy } from 'react';
import { Switch, Redirect, Route } from 'react-router-dom';
import DashboardLayout from './layouts/DashboardLayout';
import LoadingScreen from './components/LoadingScreen';
import AuthGuard from './components/AuthGuard';
import GuestGuard from './components/GuestGuard';
import SwitchGuard from './components/SwitchGuard';
import Confirm from './pages/Confirmation/index';
import useAuth from './hooks/useAuth';

export const RenderRoutes = ({ routes = [] }) => {
  const { user } = useAuth();
  const adminId = localStorage.getItem('admin_user_id');

  return (
    <Suspense fallback={<LoadingScreen />}>
      <Switch>
        {routes.map((route, i) => {
          const Guard = route.guard || Fragment;
          const Layout = route.layout || Fragment;
          const Component = route.component;

          return (
            <Route
              key={i}
              path={route.path}
              exact={route.exact}
              render={(props) => (
                <Guard>
                  <Layout>
                    {route.routes ? (
                      <RenderRoutes routes={route.routes} />
                    ) : user && (user?.verified === null || +user?.verified === 0) && !adminId ? (
                      <>
                        {user && (
                          <>
                            <Redirect to="/confirmation" />
                            <Route path="/" component={Confirm} />
                          </>
                        )}
                      </>
                    ) : (
                      <Component {...props} />
                    )}
                  </Layout>
                </Guard>
              )}
            />
          );
        })}
      </Switch>
    </Suspense>
  );
};

const routes = [
  {
    exact: true,
    path: '/404',
    component: () => <Redirect to="/" />,
  },

  {
    exact: true,
    guard: GuestGuard,
    path: '/login',
    component: lazy(() => import('./pages/Auth/LoginView')),
  },
  {
    exact: true,
    path: '/register',
    component: lazy(() => import('./pages/Register')),
  },
  {
    exact: true,
    path: '/create/reset-password',
    component: lazy(() => import('./pages/Auth/ResetPassword/CreateResetCode')),
  },
  {
    exact: true,
    path: '/reset-password',
    component: lazy(() => import('./pages/Auth/ResetPassword/ResetPassword')),
  },
  {
    exact: true,
    guard: SwitchGuard,
    path: '/switch',
    component: lazy(() => import('./pages/Auth/Switch')),
  },
  {
    path: '/',
    guard: AuthGuard,
    layout: DashboardLayout,

    routes: [
      {
        exact: true,
        path: '/',
        component: lazy(() => import('./pages/Home')),
      },
      {
        exact: true,
        path: '/confirmation',
        component: lazy(() => import('./pages/Confirmation/index')),
      },
      {
        exact: true,
        path: '/travail-à-faire',
        component: lazy(() => import('./pages/Calender')),
      },
      {
        exact: true,
        path: '/porte-monnaie',
        component: lazy(() => import('./pages/Wallet')),
      },
      {
        exact: true,
        path: '/examens',
        component: lazy(() => import('./pages/Exams')),
      },
      {
        exact: true,
        path: '/examens/:id',
        component: lazy(() => import('./pages/Exams/ExamDetails')),
      },
      {
        exact: true,
        path: '/en-direct',
        component: lazy(() => import('./pages/LiveSessions')),
      },
      {
        exact: true,
        path: '/subjects',
        component: lazy(() => import('./pages/Subjects')),
      },
      {
        exact: true,
        path: '/mon-profil',
        component: lazy(() => import('./pages/Profil')),
      },
      {
        exact: true,
        path: '/subjects/:subject_id/details',
        component: lazy(() => import('./pages/Subjects/subjectDetail')),
      },
      {
        path: '*',
        component: lazy(() => import('./pages/Auth/NotFound')),
      },
    ],
  },
];

export default routes;
