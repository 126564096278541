import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { openModal } from '../../slices/modals';
import { useDispatch } from 'react-redux';

const Index = ({ data }) => {
  const { name, img, id, color } = data;
  const dispatch = useDispatch();

  const PaymentModal = (id) => {
    switch (id) {
      case 'd17':
        return openModal('d17-payment-modal');
      case 'transfer':
        return openModal('add-point-modal', { name: 'Versement bancaire' });
      case 'online':
        return openModal('online-payment-modal');
    }
  };
  return (
    <>
      {data && (
        <>
          <div className="payment-methods-item" onClick={() => dispatch(PaymentModal(id))}>
            <img alt={name} className={color} src={img} />
            <span className="title">{name}</span>
          </div>
        </>
      )}
    </>
  );
};

Index.propTypes = {
  data: PropTypes.object,
  name: PropTypes.string,
  img: PropTypes.string,
  id: PropTypes.string,
  open: PropTypes.bool,
  openModal: PropTypes.func,
  openFormModal: PropTypes.func,
  onCloseModal: PropTypes.func,
};

export default Index;
