import { Avatar, Card, CardContent, emphasize, IconButton, makeStyles } from '@material-ui/core';
import React, { useEffect } from 'react';
import { getChildInfo, getChildPermissions, updateChildId } from '../../slices/children';
import { useSelector } from 'react-redux';
import { avatarByGender } from '../../utilities/methods';
import { SubscriptionItem } from '../../components';
import DeleteForeverIcon from '@material-ui/icons/DeleteForever';
import { openModal } from '../../slices/modals';

const index = ({ selected, child, dispatch, index, classes }) => {
  const { name, id, last_name, avatar, gender, affiliations } = child.user;

  return (
    <Card
      className={selected === true ? 'ta-card Child-item active' : 'ta-card Child-item'}
      onClick={() => {
        dispatch(updateChildId(id));
        dispatch(getChildPermissions(child.permissions));
        dispatch(getChildInfo(child));
      }}
    >
      <Avatar className="photo" alt="Moez fazzi" src={avatarByGender(avatar, gender)} />
      <DeleteForeverIcon
        className={selected ? classes.delete_non_selected : classes.delete}
        onClick={() => dispatch(openModal('delete-confirm-modal', { data: child, index: index }))}
        //   deleteChild({
        //     Id: child.parentUser,
        //     nextId: allChilds?.data[index == 0 ? index + 1 : index - 1]?.user.id,
        //   })
        // )
      />
      <div className="child-Info">
        <div className="name">{`${name} ${last_name}`}</div>
        <div className="id">{`ID:${id}`}</div>
        <SubscriptionItem affiliation={affiliations[0]} />
      </div>
    </Card>
  );
};

export default index;
