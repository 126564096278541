import { Slide } from '@material-ui/core';
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import axios from '../utilities/axios';
import { enqueueSnackbar } from './notifier';
import { headers } from '../services/api';
import removePonctuation from '../utilities/removePonctuation';

const initialState = {
  authResult: null,
  status: 'idle',
  error: null,
  confirmCodeMessage: null,
};

export const register = createAsyncThunk('auth/register', async (query, thunkAPI) => {
  const { t, ...values } = query;
  let data;
  try {
    const response = await axios.post(`/auth/parent/register`, values);

    data = await response.data;
    if (response.status === 200) {
      thunkAPI.dispatch(
        enqueueSnackbar({
          message: t(data?.message),
          options: {
            key: new Date().getTime() + Math.random(),
            variant: 'success',
            anchorOrigin: {
              vertical: 'bottom',
              horizontal: 'center',
            },
            TransitionComponent: Slide,
          },
        })
      );
      setTimeout(() => {
        window.location = '/login';
      }, 2000);

      return data.payload;
    }
    throw new Error(response.statusText);
  } catch (err) {
    let key = '';
    let message = '';
    if (err.errors) {
      if (err.errors.birthDate) {
        message = 'Age must be 16 years or older';
      }

      thunkAPI.dispatch(
        enqueueSnackbar({
          message: t(removePonctuation(message)),
          options: {
            key: new Date().getTime() + Math.random(),
            variant: 'error',
            anchorOrigin: {
              vertical: 'bottom',
              horizontal: 'center',
            },
            TransitionComponent: Slide,
          },
        })
      );
    } else {
      thunkAPI.dispatch(
        enqueueSnackbar({
          message: t(removePonctuation(err)),
          options: {
            key: new Date().getTime() + Math.random(),
            variant: 'error',
            anchorOrigin: {
              vertical: 'bottom',
              horizontal: 'center',
            },
            TransitionComponent: Slide,
          },
        })
      );
    }

    return Promise.reject(message || err || data?.message);
  }
});

export const createResetPasswordCode = createAsyncThunk(
  'auth/createResetPasswordCode ',
  async (body, thunkAPI) => {
    const { credential, t, ...reacaptcha } = body;

    let data;
    try {
      const response = await axios.get(
        `/parent/request-password?credentiel=${credential}`,
        reacaptcha,
        headers
      );
      data = await response.data;
      if (response.status === 200) {
        thunkAPI.dispatch(
          enqueueSnackbar({
            message: t(data.message),
            options: {
              key: new Date().getTime() + Math.random(),
              variant: 'success',
              anchorOrigin: {
                vertical: 'bottom',
                horizontal: 'center',
              },
              TransitionComponent: Slide,
            },
          })
        );
        setTimeout(() => {
          window.location = '/reset-password';
        }, 1000);

        return data.payload;
      }
      throw new Error(response.statusText);
    } catch (err) {
      thunkAPI.dispatch(
        enqueueSnackbar({
          message: t(err),
          options: {
            key: new Date().getTime() + Math.random(),
            variant: 'error',
            anchorOrigin: {
              vertical: 'bottom',
              horizontal: 'center',
            },
            TransitionComponent: Slide,
          },
        })
      );
      return Promise.reject(err.message ? err.message : data?.message);
    }
  }
);
export const resetPassword = createAsyncThunk('auth/resetPassword ', async (query, thunkAPI) => {
  const { t, ...values } = query;
  let data;
  try {
    const response = await axios.post(`/parent/reset-password`, values);
    data = await response.data;
    if (response.status === 200) {
      thunkAPI.dispatch(
        enqueueSnackbar({
          message: t(data.message),
          options: {
            key: new Date().getTime() + Math.random(),
            variant: 'success',
            anchorOrigin: {
              vertical: 'bottom',
              horizontal: 'center',
            },
            TransitionComponent: Slide,
          },
        })
      );
      window.location = '/login';
      return data.payload;
    }
  } catch (err) {
    thunkAPI.dispatch(
      enqueueSnackbar({
        message: t(err),
        options: {
          key: new Date().getTime() + Math.random(),
          variant: 'error',
          anchorOrigin: {
            vertical: 'bottom',
            horizontal: 'center',
          },
          TransitionComponent: Slide,
        },
      })
    );
    return Promise.reject(err.message ? err.message : data?.message);
  }
});

export const confirmCode = createAsyncThunk('auth/confirmCode', async (query, thunkAPI) => {
  const { code } = query;
  let data;
  try {
    const response = await axios.get(`/parent/confirm-registration?token=${code}`);
    data = await response.data;

    if (response.status === 200) {
      thunkAPI.dispatch(
        enqueueSnackbar({
          message: data.message,
          options: {
            key: new Date().getTime() + Math.random(),
            variant: 'success',
            anchorOrigin: {
              vertical: 'bottom',
              horizontal: 'center',
            },
            TransitionComponent: Slide,
          },
        })
      );
      window.location = '/';
      return data.payload;
    }
    //throw new Error(response.statusText);
  } catch (err) {
    thunkAPI.dispatch(
      enqueueSnackbar({
        message: err,
        options: {
          key: new Date().getTime() + Math.random(),
          variant: 'error',
          anchorOrigin: {
            vertical: 'bottom',
            horizontal: 'center',
          },
          TransitionComponent: Slide,
        },
      })
    );
    return Promise.reject(err.message ? err.message : data?.message);
  }
});

export const resendConfirmCode = createAsyncThunk('auth/resendConfirmCode', async (query) => {
  let data;
  try {
    const response = await axios.get(`/parent/resend-code`);
    data = await response.data;
    if (response.status === 200) {
      return data?.payload;
    }
    throw new Error(response.statusText);
  } catch (err) {
    return Promise.reject(err.message ? err.message : data?.message);
  }
});

const slice = createSlice({
  name: 'auth',
  initialState,
  reducers: {},
  extraReducers: {
    [register.pending]: (state, action) => {
      state.status = 'loading';
    },
    [register.fulfilled]: (state, action) => {
      state.status = 'succeeded';
      state.authResult = action.payload;
    },
    [register.rejected]: (state, action) => {
      state.status = 'failed';
      state.error = action.error.message;
    },
    [createResetPasswordCode.pending]: (state, action) => {
      state.status = 'loading';
    },
    [createResetPasswordCode.fulfilled]: (state, action) => {
      state.status = 'succeeded';
      state.authResult = action.payload.message;
    },
    [createResetPasswordCode.rejected]: (state, action) => {
      state.status = 'failed';
      state.error = action.payload;
    },
    [resetPassword.pending]: (state, action) => {
      state.status = 'loading';
    },
    [resetPassword.fulfilled]: (state, action) => {
      state.status = 'succeeded';
      state.authResult = action.payload.message;
    },
    [resetPassword.rejected]: (state, action) => {
      state.status = 'failed';
      state.error = action.payload;
    },
    [confirmCode.pending]: (state, action) => {
      state.status = 'loading';
    },
    [confirmCode.fulfilled]: (state, action) => {
      state.status = 'succeeded';
      state.confirmCodeMessage = action.payload.message;
    },
    [confirmCode.rejected]: (state, action) => {
      state.status = 'failed';
      state.error = action.payload;
    },
    [resendConfirmCode.pending]: (state, action) => {
      state.status = 'loading';
    },
    [resendConfirmCode.fulfilled]: (state, action) => {
      state.status = 'succeeded';
      state.confirmCodeMessage = action.payload.message;
    },
    [resendConfirmCode.rejected]: (state, action) => {
      state.status = 'failed';
      state.error = action.payload;
    },
  },
});

export const reducer = slice.reducer;

export default slice;
