import axios from 'axios';

const axiosInstance = axios.create({ baseURL: process.env.REACT_APP_API_URL });
axiosInstance.interceptors.response.use(
  (response) => response,
  (error) => {
    // if (error.response.status === 401) {
    //   localStorage.removeItem('token');
    //   window.location.pathname !== '/login' && window.location.reload();
    // } else {
    throw error.response.data.message ? error.response.data.message : error.response.data;
    // }
  }
);

export default axiosInstance;
