import 'react-app-polyfill/ie11';
import 'react-app-polyfill/stable';
import 'react-perfect-scrollbar/dist/css/styles.css';
import 'nprogress/nprogress.css';

import React from 'react';
import ReactDOM from 'react-dom';
import { enableES5 } from 'immer';
import { Provider } from 'react-redux';
import { I18nextProvider } from 'react-i18next';
import App from './App';
import i18n from './services/i18n';
import store from './store';
import * as serviceWorker from './serviceWorker';
import { SettingsProvider } from './contexts/SettingsContext';
import Bugsnag from '@bugsnag/js';
import BugsnagPluginReact from '@bugsnag/plugin-react';

Bugsnag.start({
  apiKey: process.env.REACT_APP_PARENT_BUGSNAG,
  plugins: [new BugsnagPluginReact()],
  releaseStage: process.env.REACT_APP_BUGSNAG_ENV,
  enabledReleaseStages: ['production', 'staging', 'local', 'development', 'test'],
  // otherOptions: value
});

const ErrorBoundary = Bugsnag.getPlugin('react').createErrorBoundary(React);

enableES5();

ReactDOM.render(
  // <React.StrictMode>
  <I18nextProvider i18n={i18n}>
    <Provider store={store}>
      <SettingsProvider>
        <ErrorBoundary>
          <App />
        </ErrorBoundary>
      </SettingsProvider>
    </Provider>
  </I18nextProvider>,
  document.getElementById('root')
);

serviceWorker.unregister();
