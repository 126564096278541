import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import Im_Wallet from '../../assets/img/icons/wallet.svg';
import { useSelector } from 'react-redux';

const Index = ({ inputValue }) => {
  const { user } = useSelector((state) => state.user);

  return (
    <div
      className={inputValue ? 'search-show' : 'wallet-widget'}
      onClick={(e) => (window.location = '/porte-monnaie')}
    >
      <img alt="wallet-icon" src={Im_Wallet} />
      <div>
        <span className="title">Votre solde</span>
        <span className="amount">{user?.points ? user?.points : 0} Pts</span>
      </div>
    </div>
  );
};

Index.propTypes = {
  points: PropTypes.string,
};

export default Index;
