import React from 'react';
import PropTypes from 'prop-types';
import { TodayEventsItem } from '../../components';
import Empty from '../Empty/Empty';
import ImEvent from '../../assets/img/icons/event.svg';

const Index = ({ events }) => {
  return (
    <div className="today-events-list" style={{ width: '100%' }}>
      {events && events.length > 0 ? (
        events.map((event, index) => {
          return <TodayEventsItem event={event} key={index} />;
        })
      ) : (
        <Empty label="Pas de séances." icon={ImEvent} />
      )}
    </div>
  );
};

Index.propTypes = {
  events: PropTypes.array,
};

export default Index;
