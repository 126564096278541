import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { withStyles } from '@material-ui/core/styles';
import LinearProgress from '@material-ui/core/LinearProgress';
import * as dayjs from 'dayjs';
import customParseFormat from 'dayjs/plugin/customParseFormat';
import { ptr } from '../../utilities/methods';

const BorderLinearProgress = withStyles((theme) => ({
  root: {
    height: 10,
    borderRadius: 5,
  },
  colorPrimary: {
    backgroundColor: theme.palette.grey[theme.palette.type === 'light' ? 200 : 700],
  },
  bar: {
    borderRadius: 5,
    backgroundColor: '#FE726E',
  },
}))(LinearProgress);

dayjs.extend(customParseFormat);

const Index = ({ index, affiliation }) => {
  const { t } = useTranslation();
  return (
    <div className="subs-item-2" key={index}>
      {affiliation && (
        <>
          <span className="label">{affiliation?.group?.name}</span>
          <BorderLinearProgress
            variant="determinate"
            value={ptr(affiliation?.start_date, affiliation?.end_date)}
          />
          <span className="due-date">
            {t('Se termine le ')}
            {`${dayjs(affiliation?.end_date).format('DD/MM/YYYY')}`}
          </span>{' '}
        </>
      )}
    </div>
  );
};

Index.propTypes = {
  index: PropTypes.number,
  affiliation: PropTypes.object,
  ptr: PropTypes.func,
};

export default Index;
