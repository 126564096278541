import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { fetchUser } from '../../../slices/user';
import ContainerItems from './ContainerItems';
import constants from '../../../utilities/constants';

const list = constants.SIDEBAR_LIST;

const Container = ({ handleDrawerToggle, mobileOpen }) => {
  const dispatch = useDispatch();
  const { user, status } = useSelector((state) => state.user);
  const { permissions } = useSelector((state) => state.children);

  useEffect(() => {
    if (status === 'idle') {
      dispatch(fetchUser());
    }
  }, [status, dispatch]);
  const getHomePage = (list) => {
    let home;
    home = list.find((item) => item.url === '/');
    return home;
  };
  // useEffect(()=>{
  //   dispatch(isAllowed())
  // },[permissions])

  const isAllowed = () => {
    if (permissions?.approved == null || permissions?.approved === false) {
      return false;
    }
    return true;
  };

  return (
    <div className="container">
      <ul>
        {list &&
          list.length > 0 &&
          list.map((item, index) => {
            //if ( !isAllowed()) return null
            return (
              <ContainerItems
                permission={isAllowed()}
                key={index}
                item={item}
                handleDrawerToggle={handleDrawerToggle}
                mobileOpen={mobileOpen}
              />
            );
          })}
      </ul>
    </div>
  );
};

Container.propTypes = {};

export default Container;
