import React, { useEffect } from 'react';
import { BrowserRouter } from 'react-router-dom';
import { SnackbarProvider } from 'notistack';
import './App.scss';
import { useTranslation } from 'react-i18next';
import { makeStyles } from '@material-ui/core/styles';
import CssBaseline from '@material-ui/core/CssBaseline';
import { Notifier } from './layouts';
import { ModalsProvider } from './components';
import { AuthProvider } from './contexts/JWTAuthContext';
import routes, { RenderRoutes } from './routes';
import { useDarkMode } from './utilities/useDarkMode';
import useSettings from './hooks/useSettings';
import { getClicToPayEchec, getClicToPaySuccess } from './slices/points';
import { useDispatch } from 'react-redux';

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
  },
  toolbar: theme.mixins.toolbar,
  content: {
    flexGrow: 1,
    padding: theme.spacing(3),
  },
}));

function App() {
  const { settings } = useSettings();
  const { i18n } = useTranslation();
  const { language } = i18n;

  const classes = useStyles();
  const [theme, componentMounted] = useDarkMode();
  const dispatch = useDispatch();
  const queryString = require('query-string');
  useEffect(() => {
    if (window.location.href.includes('payment/success')) {
      const search = window.location.search;
      const query = queryString.parse(search);
      const orderId = query.orderId;
      dispatch(getClicToPaySuccess(orderId));
    }
    if (window.location.href.includes('payment/echec')) {
      const search = window.location.search;
      const query = queryString.parse(search);
      const orderId = query.orderId;
      dispatch(getClicToPayEchec(orderId));
    }
  }, [dispatch]);
  if (!componentMounted) {
    return <div />;
  }
  const wrapperClass = ` wrapper${theme === 'dark' ? ' dark' : ''}${
    language === 'ar' ? ' rtl' : ''
  }${settings.sidebar === 'full' ? ' sidebar-full' : ' sidebar-mini'}`;

  const rtlStyle = {
    direction: 'rtl',
    textAlign: 'right',
  };

  return (
    <div className={classes.root + wrapperClass}>
      <CssBaseline />
      <SnackbarProvider
        maxSnack={12}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        style={language === 'ar' && rtlStyle}
      >
        <Notifier />
        <BrowserRouter>
          <AuthProvider>
            <RenderRoutes routes={routes} />
            <ModalsProvider />
          </AuthProvider>
        </BrowserRouter>
      </SnackbarProvider>
    </div>
  );
}

export default App;
