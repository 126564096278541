import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { fetchUser, addConfirmCode } from '../../slices/user';
import { useSelector, useDispatch } from 'react-redux';
import { Formik } from 'formik';
import * as Yup from 'yup';
import Button from '@material-ui/core/Button';
import {
  TextField,
  Box,
  FormHelperText,
  Typography,
  Switch,
  CircularProgress,
} from '@material-ui/core';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import useIsMountedRef from '../../hooks/useIsMountedRef';

const ConfirmCodeModal = ({ id, open, handleClose, ...rest }) => {
  const dispatch = useDispatch();
  const { user, status, error } = useSelector((state) => state.user);
  const isMountedRef = useIsMountedRef();

  useEffect(() => {
    if (status === 'idle' && open === true) {
      dispatch(fetchUser());
    }
  }, [open, status, dispatch]);

  return (
    <Dialog
      open={open}
      onClose={() => handleClose(id)}
      disableBackdropClick={true}
      disableEscapeKeyDown={true}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      className="ta-modal add-video-modal"
    >
      <DialogTitle id="alert-dialog-title">
        <span className="label">Modifier email</span>
        <span className="close-btn" onClick={() => handleClose(id)}>
          Fermer
        </span>
      </DialogTitle>
      <DialogContent className="ta-modal-content-scroll">
        <Formik
          initialValues={{
            email: '',
            submit: null,
          }}
          validationSchema={Yup.object().shape({
            email: Yup.string().email().max(255).required('Email est requis'),
            confirmationToken: Yup.string().max(255).required('Code de confirmation est requis'),
          })}
          onSubmit={async (values, { setErrors, setStatus, setSubmitting }) => {
            try {
              dispatch(
                addConfirmCode({
                  email: values.email,
                  confirmationToken: values.confirmationToken,
                })
              );
              if (isMountedRef.current) {
                setStatus({ success: true });
                setSubmitting(false);
              }
            } catch (err) {
              console.error(err);
              if (isMountedRef.current) {
                setStatus({ success: false });
                setErrors({ submit: err.message });
                setSubmitting(false);
              }
            }
          }}
        >
          {({
            errors,
            handleBlur,
            handleChange,
            handleSubmit,
            isSubmitting,
            touched,
            values,
            resetForm,
            setFieldValue,
            setTouched,
          }) => (
            <form noValidate onSubmit={handleSubmit} className="ta-form" {...rest}>
              <Typography variant="body2" color="textSecondary" className="label">
                Confirmer votre email*
              </Typography>
              <TextField
                className="input"
                error={Boolean(touched.email && errors.email)}
                fullWidth
                helperText={touched.email && errors.email}
                // label="Title"
                margin="normal"
                name="email"
                onBlur={handleBlur}
                onChange={handleChange}
                type="text"
                value={values.email}
                variant="outlined"
                size="small"
              />
              <Typography variant="body2" color="textSecondary" className="label">
                Ajouter votre code de confirmation*
              </Typography>
              <TextField
                className="input"
                error={Boolean(touched.confirmationToken && errors.confirmationToken)}
                fullWidth
                helperText={touched.confirmationToken && errors.confirmationToken}
                // label="Title"
                margin="normal"
                name="confirmationToken"
                onBlur={handleBlur}
                onChange={handleChange}
                type="text"
                value={values.confirmationToken}
                variant="outlined"
                size="small"
              />

              {errors.submit && (
                <Box mt={3}>
                  <FormHelperText error>{errors.submit}</FormHelperText>
                </Box>
              )}
              <Box mt={2} className="ta-btns-group">
                <Button
                  disabled={isSubmitting}
                  type="submit"
                  className="ta-btn primary btn-rounded btn-lg"
                >
                  {status == 'loading' ? (
                    <CircularProgress style={{ color: 'white' }} />
                  ) : (
                    'Confirmer'
                  )}
                </Button>
                <Button
                  disabled={isSubmitting || status == 'loading'}
                  type="reset"
                  className="ta-btn btn-rounded btn-lg blue"
                  onClick={resetForm}
                >
                  Annuler
                </Button>
              </Box>
            </form>
          )}
        </Formik>
      </DialogContent>
    </Dialog>
  );
};

ConfirmCodeModal.propTypes = {};

export default ConfirmCodeModal;
