import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import axios from '../utilities/axios';
import { ord } from '../utilities/methods';

const initialState = {
  chapters: [],
  chaptersSubject: null,
  status: 'idle',
  error: null,
};

export const fetchChapters = createAsyncThunk(
  'chapters/fetchChapters ',
  async ({ selectedChild, subject_slug }) => {
    let data;
    try {
      const response = await axios.get(
        `/parent/${selectedChild}/subjects/${subject_slug}/chapters`
      );
      data = await response.data;
      if (response.status === 200) {
        return data.payload;
      }
      throw new Error(response.statusText);
    } catch (err) {
      console.log(err);
      return Promise.reject(err.message ? err.message : data?.message);
    }
  }
);

const slice = createSlice({
  name: 'chapters ',
  initialState,
  reducers: {},
  extraReducers: {
    [fetchChapters.pending]: (state, action) => {
      state.status = 'loading';
    },
    [fetchChapters.fulfilled]: (state, action) => {
      state.status = 'succeeded';
      state.chapters = action.payload.chapters;
      state.chaptersSubject = action.payload.subject;
    },
    [fetchChapters.rejected]: (state, action) => {
      state.status = 'failed';
      state.error = action.payload;
    },
  },
});

export const reducer = slice.reducer;

export default slice;
