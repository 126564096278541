import React from 'react';
import { MyProgressItem } from '../../components';
import PropTypes from 'prop-types';

const Index = ({ subjects, subjectsProgress }) => {
  return (
    <div className="my-progress-list">
      {subjects &&
        subjects.length > 0 &&
        subjects.map((subject, index) => {
          const subjectProgress =
            subjectsProgress &&
            subjectsProgress.length > 0 &&
            subjectsProgress.find((p) => p.subject_id === subject.id);
          const progress =
            subjectProgress && subject?.subjectVideos !== 0
              ? Math.round((subjectProgress?.nbCompleted / subject?.subjectVideos) * 100)
              : 0;
          return (
            <MyProgressItem
              data={subject}
              key={index}
              subjectProgress={progress > 100 ? 100 : progress}
            />
          );
        })}
    </div>
  );
};

Index.propTypes = {
  data: PropTypes.array,
  subjects: PropTypes.array,
};

export default Index;
