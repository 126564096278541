export const diffTimes = (start, end) => {
  start = start.split(':');
  end = end.split(':');
  let startDate = new Date(0, 0, 0, start[0], start[1], 0);
  let endDate = new Date(0, 0, 0, end[0], end[1], 0);
  let diff = endDate.getTime() - startDate.getTime();
  let minutes = Math.floor(diff / 1000 / 60) / 60;
  return minutes;
};

export const convertSecondDuration = (time) => {
  return new Date(parseInt(time) * 1000).toISOString().substr(11, 8);
};
