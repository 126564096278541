import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import Card from '@material-ui/core/Card';
import CardHeader from '@material-ui/core/CardHeader';
import CardContent from '@material-ui/core/CardContent';
import { CardTitle, ResponsiveTable, Empty } from '../../components';
//import { getPointsHistory } from '../../redux/slices/points';
import { useDispatch, useSelector } from 'react-redux';
import { fetchPointsHistory } from '../../slices/points';
//import ImPoints from '../../assets/img/icons/discount.svg';

const Index = () => {
  const { pointsHistory } = useSelector((state) => state.points);
  const [data, setData] = useState([]);
  const dispatch = useDispatch();
  const headers = [
    { accessor: 'reference', label: 'Code' },
    { accessor: 'methode', label: 'Mode de paiement' },
    { accessor: 'amount', label: 'Montant en dinars' },
    { accessor: 'comment', label: 'Description' },
    { accessor: 'created_at', label: 'Date', type: 'date' },
    { accessor: 'status', label: 'Statut' },
  ];
  useEffect(() => {
    if (!pointsHistory) dispatch(fetchPointsHistory());
  }, []);
  useEffect(() => {
    if (pointsHistory) {
      setData(pointsHistory || []);
    }
  }, [pointsHistory]);
  return (
    <Card className="ta-card">
      <CardHeader title={<CardTitle title="Historique des points" />} />
      <CardContent>
        <div className="child-full">
          <ResponsiveTable
            className={'home-page'}
            cols={headers}
            rows={pointsHistory}
            emptyComponent={<Empty label="Pas de points." className="no-borders" />}
          />
        </div>
      </CardContent>
    </Card>
  );
};

Index.propTypes = {
  points_history: PropTypes.array,
  data: PropTypes.array,
  setData: PropTypes.func,
  dispatch: PropTypes.func,
  headers: PropTypes.array,
};

export default Index;
