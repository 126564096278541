import React from 'react';
import { Formik } from 'formik';
import * as Yup from 'yup';
import Button from '@material-ui/core/Button';
import { TextField, Box, FormHelperText, Typography } from '@material-ui/core';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import useIsMountedRef from '../../hooks/useIsMountedRef';
import AttachmentsSession from '../../components/AttachementsSession';
import parse from 'html-react-parser';
import { useDispatch } from 'react-redux';
import BlueButton from '../BlueButton';

const Event = ({ id, open, handleClose, data, ...rest }) => {
  const isMountedRef = useIsMountedRef();

  return (
    <Dialog
      open={open}
      onClose={(e, reason) => {
        if (reason !== 'backdropClick' && reason !== 'escapeKeyDown') {
          handleClose(id);
        }
      }}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      className="ta-modal "
    >
      <DialogTitle id="alert-dialog-title">
        <span className="label">Séance en direct</span>
        <span className="close-btn" onClick={() => handleClose(id)}>
          Fermer
        </span>
      </DialogTitle>
      <DialogContent className="ta-modal-content-scroll">
        <Formik
          initialValues={{
            name: '',
            submit: null,
          }}
          validationSchema={Yup.object().shape({
            name: Yup.string().max(255).required('Title is required'),
          })}
          onSubmit={async (values, { setErrors, setStatus, setSubmitting }) => {
            try {
              if (isMountedRef.current) {
                setStatus({ success: true });
                setSubmitting(false);
              }
            } catch (err) {
              console.error(err);
              if (isMountedRef.current) {
                setStatus({ success: false });
                setErrors({ submit: err.message });
                setSubmitting(false);
              }
            }
          }}
        >
          {({
            errors,
            handleBlur,
            handleChange,
            handleSubmit,
            isSubmitting,
            touched,
            values,
            resetForm,
            setFieldValue,
            setTouched,
          }) => (
            <form noValidate onSubmit={handleSubmit} className="sessionModal" {...rest}>
              <div>
                <BlueButton
                  text={data?.subject?.name}
                  width={data?.subject?.name.length * 7 >= 60 ? data.subject?.name?.length * 7 : 70}
                  height={'100%'}
                  fontSize={10}
                />
                <Typography variant="h6" className="sessionDescription">
                  {data && data?.name}
                </Typography>
                <div className="modalLine"></div>
                <div className="sessionInfo">
                  <Typography variant="h8" className="infoSpan">
                    Instructeur :
                    <Typography variant="h8" className="infoValue">
                      {data?.teacher}
                    </Typography>
                  </Typography>
                  <Typography variant="h8" className="infoSpan">
                    Niveaux :
                    <Typography variant="h8" className="levels">
                      {data.levels}
                    </Typography>
                  </Typography>
                </div>
                <Typography variant="h8" className="infoSpan">
                  Heure :
                  <Typography variant="h8" className="infoValue">
                    {data.event?.start_time}
                  </Typography>
                </Typography>
                <div className="sessionDocuments">
                  {data.event?.files?.length === true && (
                    <Typography variant="h7" className="sessionDescription">
                      Travail à faire :
                    </Typography>
                  )}
                  {data?.event?.files?.map((file) => {
                    return file && <AttachmentsSession calender={true} file={file && file} />;
                  })}
                </div>
              </div>
              {errors.submit && (
                <Box mt={3}>
                  <FormHelperText error>{errors.submit}</FormHelperText>
                </Box>
              )}
              {/* <Box mt={2} className="ta-btns-group">
                <Button
                  disabled={isSubmitting}
                  type="submit"
                  className="ta-btn primary btn-rounded btn-lg"
                >
                  Ajouter
                </Button>
                <Button
                  disabled={isSubmitting}
                  type="reset"
                  className="ta-btn btn-rounded btn-lg"
                  onClick={resetForm}
                >
                  Annuler
                </Button>
              </Box> */}
            </form>
          )}
        </Formik>
      </DialogContent>
    </Dialog>
  );
};

export default Event;
