import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import axios from '../utilities/axios';
import { ord } from '../utilities/methods';

const initialState = {
  subjects: null,
  status: 'idle',
  error: null,
};

export const fetchSubjects = createAsyncThunk('subjects/fetchSubjects', async (selectedChild) => {
  let data;
  try {
    const response = await axios.get(`/parent/${selectedChild}/subjects`);
    data = await response.data;
    if (response.status === 200) {
      return data.payload;
    }
    throw new Error(response.statusText);
  } catch (err) {
    console.log(err);
    return Promise.reject(err.message ? err.message : data?.message);
  }
});

const slice = createSlice({
  name: 'subjects',
  initialState,
  reducers: {},
  extraReducers: {
    [fetchSubjects.pending]: (state, action) => {
      state.status = 'loading';
    },
    [fetchSubjects.fulfilled]: (state, action) => {
      state.status = 'succeeded';
      const filtered_data = action.payload.sort((a, b) =>
        parseInt(a.position) > parseInt(b.position) ? 1 : -1
      );
      const options = [
        { label: 'id', accessor: 'id' },
        { label: 'label', accessor: 'name' },
        { label: 'img', accessor: 'thumbnail' },
        { label: 'position', accessor: 'position' },
        { label: 'slug', accessor: 'slug' },
        { label: 'subjectVideos', accessor: 'subjectVideos' },
        // { label: 'color', accessor: 'color' },
        // { label: 'active', accessor: 'visible' },
      ];
      state.subjects = ord(filtered_data, options);
    },
    [fetchSubjects.rejected]: (state, action) => {
      state.status = 'failed';
      state.error = action.payload;
    },
  },
});

export const reducer = slice.reducer;

export default slice;
