import React, { useEffect, useState } from 'react';
import { Formik } from 'formik';
import * as Yup from 'yup';
import Button from '@material-ui/core/Button';
import { TextField, Box } from '@material-ui/core';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import useIsMountedRef from '../../hooks/useIsMountedRef';
import { transferPoints } from '../../slices/points';
import { useDispatch, useSelector } from 'react-redux';
import { Autocomplete } from '@material-ui/lab';
import jwt_decode from 'jwt-decode';
import { openModal } from '../../slices/modals';

const TransferPoint = ({ id, open, handleClose, data, ...rest }) => {
  const dispatch = useDispatch();
  const { allChilds } = useSelector((state) => state.children);
  const isMountedRef = useIsMountedRef();
  const token = localStorage.getItem('token');
  const decoded = jwt_decode(token);
  const adminId = decoded.hasOwnProperty('adminId') ? decoded?.adminId : null;
  const [openConfirmModal, setOpenConfirmModal] = useState(false);

  const handleOptions = (childList) => {
    let arr = [];
    if (childList) {
      childList.map((item) => arr.push(item.user));

      arr.push({ name: "Ajouter l'identifiant d'un élève" });
    }
    return arr;
  };

  return (
    <Dialog
      open={open}
      onClose={(e, reason) => {
        if (reason !== 'backdropClick' && reason !== 'escapeKeyDown') {
          handleClose(id);
        }
      }}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      className="ta-modal add-video-modal"
    >
      <DialogTitle id="alert-dialog-title">
        <span className="label">Transférer des points</span>
        <span className="close-btn" onClick={() => handleClose(id)}>
          Fermer
        </span>
      </DialogTitle>
      <DialogContent className="ta-modal-content-scroll">
        <Formik
          initialValues={{
            points: '',
            toUser: '',
            admin: '',
            submit: null,
            userId: '',
          }}
          validationSchema={Yup.object().shape({
            points: Yup.number()
              .typeError('Le montant doit être un nombre')
              .max(5000, 'Le montant doit être inférieur ou égal à 5000')
              .required('Le champ montant est obligatoire'),
            toUser: Yup.object().required('Le champ identifiant est obligatoire ').nullable(),
            userId: Yup.string().when('toUser', {
              is: (value) => value?.name === "Ajouter l'identifiant d'un élève",
              then: Yup.string().required('Le champ identifiant est obligatoire'),
            }),
          })}
          onSubmit={async (values, { setErrors, setStatus, setSubmitting }) => {
            try {
              let dataToSend = {
                points: values.points,
                toUser: values.userId ? values.userId : values?.toUser?.id,
                admin: adminId,
              };
              dispatch(transferPoints({ values: dataToSend }));

              if (isMountedRef.current) {
                setStatus({ success: true });
                setSubmitting(false);
                setOpenConfirmModal(true);
              }
            } catch (err) {
              console.error(err);
              if (isMountedRef.current) {
                setStatus({ success: false });
                setErrors({ submit: err.message });
                setSubmitting(false);
              }
            }
          }}
        >
          {({
            errors,
            handleBlur,
            handleChange,
            handleSubmit,
            isSubmitting,
            touched,
            values,
            resetForm,
            setFieldValue,
            setTouched,
          }) => (
            <form noValidate onSubmit={handleSubmit} className="ta-form" {...rest}>
              <Autocomplete
                //className="input m-size"
                id="tags-outlined"
                blurOnSelect
                name="toUser"
                onBlur={() => setTouched({ toUser: true })}
                fullWidth
                size="small"
                onChange={(e, value) => setFieldValue('toUser', value)}
                getOptionSelected={(option, value) => option?.name === value?.name}
                options={handleOptions(allChilds?.data)}
                value={values?.toUser}
                getOptionLabel={(option) =>
                  option
                    ? `${option?.id || ''} ${option?.name || ''} ${option?.last_name || ''}`
                    : ''
                }
                renderInput={(params) => (
                  <TextField
                    error={Boolean(touched.toUser && errors.toUser)}
                    {...params}
                    //className= {classes.input}
                    variant="outlined"
                    placeholder="Identifiant de votre enfant"
                    margin="dense"
                  />
                )}
              />
              {values.toUser?.name === "Ajouter l'identifiant d'un élève" && (
                <TextField
                  className="input"
                  error={Boolean(touched.userId && errors.userId)}
                  fullWidth
                  helperText={touched.userId && errors.userId}
                  margin="dense"
                  name="userId"
                  onBlur={handleBlur}
                  onChange={handleChange}
                  type="text"
                  value={values.UserId}
                  variant="outlined"
                  size="small"
                  placeholder="Identifiant de votre enfant"
                />
              )}
              <TextField
                className="input"
                error={Boolean(touched.points && errors.points)}
                fullWidth
                helperText={touched.points && errors.points}
                margin="dense"
                name="points"
                onBlur={handleBlur}
                onChange={handleChange}
                type="number"
                value={values.points}
                variant="outlined"
                size="small"
                placeholder="Montant en dinar"
              />
              <Box mt={2} className="ta-btns-group right">
                <Button
                  disabled={isSubmitting}
                  type="submit"
                  className="ta-btn primary btn-rounded btn-xl"
                >
                  Transférer des points
                </Button>
              </Box>
            </form>
          )}
        </Formik>
      </DialogContent>
    </Dialog>
  );
};

export default TransferPoint;
