import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { withStyles } from '@material-ui/core/styles';
import LinearProgress from '@material-ui/core/LinearProgress';

const BorderLinearProgress = withStyles((theme) => ({
  root: {
    height: 10,
    borderRadius: 5,
  },
  colorPrimary: {
    backgroundColor: theme.palette.grey[theme.palette.type === 'light' ? 200 : 700],
  },
  bar: (props) => ({
    borderRadius: 5,
    backgroundColor: props.color,
  }),
}))(LinearProgress);

const Index = ({ data, subjectProgress }) => {
  const { label, slug } = data;
  return (
    <>
      {data && (
        <Link className="my-progress-item" to={`/subjects/${slug}/details`}>
          <div className="left">
            <div className="title">
              <img src={data.img} alt={data.label} />
              <span>{label}</span>
            </div>
            <BorderLinearProgress
              variant="determinate"
              value={subjectProgress}
              color={data.color ? data.color : '#ec5542'}
              className={data.color}
            />
          </div>
          <div className="right">
            <span>{`${subjectProgress}%`}</span>
          </div>
        </Link>
      )}
    </>
  );
};

Index.propTypes = {
  data: PropTypes.object,
  dummy: PropTypes.object,
  label: PropTypes.string,
  slug: PropTypes.string,
  icon: PropTypes.string,
  progress: PropTypes.number,
  color: PropTypes.string,
};

export default Index;
