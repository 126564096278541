import React from 'react';
import PropTypes from 'prop-types';
import Divider from '@material-ui/core/Divider';
import Im_Wallet from '../../assets/img/icons/Wallet-img.svg';

const Index = ({ points, external }) => {
  return (
    <div className="wallet-balance">
      <span className={!!!external ? 'label' : 'label muted'}>
        Votre solde actuel: {!!external && <span className="points">{`${points} Pts`}</span>}
      </span>
      <Divider variant="middle" />
      {!!!external && <span className="points">{`${points} Pts`}</span>}
      <img alt="wallet-icon" src={Im_Wallet} className={!!external && 'mini-img'} />
    </div>
  );
};

Index.propTypes = {
  points: PropTypes.string,
  external: PropTypes.bool,
};

export default Index;
