import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import {
  AddChildModal,
  AddPointModal,
  EditEmailModal,
  EditPasswordModal,
  EventModal,
  OnlinePaymentModal,
  PaymentD17Modal,
  PointTransferModal,
  QrCodeModal,
  ConfirmCodeModal,
  DeleteConfirmationModal,
} from '../Modals';
import { closeModal } from '../../slices/modals';
import useAuth from '../../hooks/useAuth';
import ConfirmationTransferPoints from '../Modals/ConfirmationTransferPoints';

const ModalsProvider = (props) => {
  const { isAuthenticated } = useAuth();
  const { modals } = useSelector((state) => state.modals);
  const dispatch = useDispatch();
  const modalState = (id, key) => {
    const res = modals.find((modal) => modal.id === id);
    return res[key];
  };
  const handleClose = (id) => {
    dispatch(closeModal(id));
  };
  return isAuthenticated ? (
    <>
      <AddChildModal
        id="add-child-modal"
        open={modalState('add-child-modal', 'open')}
        data={modalState('add-child-modal', 'data')}
        handleClose={handleClose}
      />

      <AddPointModal
        id="add-point-modal"
        open={modalState('add-point-modal', 'open')}
        data={modalState('add-point-modal', 'data')}
        handleClose={handleClose}
      />
      <PointTransferModal
        id="point-transfer-modal"
        open={modalState('point-transfer-modal', 'open')}
        data={modalState('point-transfer-modal', 'data')}
        handleClose={handleClose}
      />
      <OnlinePaymentModal
        id="online-payment-modal"
        open={modalState('online-payment-modal', 'open')}
        data={modalState('online-payment-modal', 'data')}
        handleClose={handleClose}
      />

      <PaymentD17Modal
        id="d17-payment-modal"
        open={modalState('d17-payment-modal', 'open')}
        data={modalState('d17-payment-modal', 'data')}
        handleClose={handleClose}
      />
      <EventModal
        id="event-show-modal"
        open={modalState('event-show-modal', 'open')}
        data={modalState('event-show-modal', 'data')}
        handleClose={handleClose}
      />
      <QrCodeModal
        id="qr-code-modal"
        open={modalState('qr-code-modal', 'open')}
        data={modalState('qr-code-modal', 'data')}
        handleClose={handleClose}
      />
      <EditEmailModal
        id="edit-email-modal"
        open={modalState('edit-email-modal', 'open')}
        data={modalState('edit-email-modal', 'data')}
        handleClose={handleClose}
      />
      <EditPasswordModal
        id="edit-password-modal"
        open={modalState('edit-password-modal', 'open')}
        data={modalState('edit-password-modal', 'data')}
        handleClose={handleClose}
      />
      <ConfirmCodeModal
        id="confirm-code-modal"
        open={modalState('confirm-code-modal', 'open')}
        data={modalState('confirm-code-modal', 'data')}
        handleClose={handleClose}
      />
      <DeleteConfirmationModal
        id="delete-confirm-modal"
        open={modalState('delete-confirm-modal', 'open')}
        data={modalState('delete-confirm-modal', 'data')}
        handleClose={handleClose}
      />
      <ConfirmationTransferPoints
        id="confirm-transfer-point-modal"
        open={modalState('confirm-transfer-point-modal', 'open')}
        data={modalState('confirm-transfer-point-modal', 'data')}
        handleClose={handleClose}
      />
    </>
  ) : null;
};

export default ModalsProvider;
