import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import axios from '../utilities/axios';
///import { enqueueSnackbar } from './notifier';
//import { closeModal } from './modals';
//import Slide from '@material-ui/core/Slide';

const initialState = {
  exams: [],
  exam: null,
  subjects: [],
  status: 'idle',
  error: null,
};

export const fetchExams = createAsyncThunk('exams/fetchExams', async ({ selectedChild }) => {
  let data;
  try {
    const response = await axios.get(`/parent/${selectedChild}/exams`);
    data = await response.data;
    if (response.status === 200) {
      return data?.payload;
    }
    throw new Error(response.statusText);
  } catch (err) {
    console.log(err);
    return Promise.reject(err.message ? err.message : data?.message);
  }
});

export const fetchExam = createAsyncThunk('exams/fetchExam', async ({ selectedChild, examId }) => {
  let data;
  try {
    const response = await axios.get(`/parent/${selectedChild}/exam/${examId}`);
    data = await response.data;
    if (response.status === 200) {
      return data?.payload;
    }
    throw new Error(response.statusText);
  } catch (err) {
    console.log(err);
    return Promise.reject(err.message ? err.message : data?.message);
  }
});

const slice = createSlice({
  name: 'exams',
  initialState,
  reducers: {},
  extraReducers: {
    [fetchExams.pending]: (state, action) => {
      state.status = 'loading';
    },
    [fetchExams.fulfilled]: (state, action) => {
      state.status = 'succeeded';
      if (action?.payload && action?.payload?.length > 0) {
        state.exams = action.payload?.contents;
        state.subjects = action.payload?.subjects;
      } else {
        state.exams = action.payload?.contents;
        state.subjects = action.payload?.subjects;
      }
    },
    [fetchExams.rejected]: (state, action) => {
      state.status = 'failed';
      state.error = action.payload;
    },
    [fetchExam.pending]: (state, action) => {
      state.status = 'loading';
    },
    [fetchExam.fulfilled]: (state, action) => {
      state.status = 'succeeded';
      state.exam = action?.payload;
    },
    [fetchExam.rejected]: (state, action) => {
      state.status = 'failed';
      state.error = action.payload;
    },
  },
});

export const reducer = slice.reducer;

export default slice;
