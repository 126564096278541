import React, { useEffect, useState } from 'react';
import ChildItem from '../ChildItem';
import { AddChildButton } from '../../components';
import { getAllChild } from '../../slices/children';
import { useDispatch, useSelector } from 'react-redux';
import { makeStyles } from '@material-ui/core';
const useStyles = makeStyles(() => ({
  delete: {
    color: '#ec5542',
    '&:hover': {
      borderRadius: 4,
      opacity: '0.8',
      cursor: 'pointer',
      clear: 'both',
    },
  },
  delete_non_selected: {
    '&:hover': {
      borderRadius: 4,
      opacity: '0.8',
      cursor: 'pointer',
      clear: 'both',
    },
  },
}));

const ChildList = (props) => {
  const { allChilds, selectedChild } = useSelector((state) => state.children);
  const dispatch = useDispatch();
  const classes = useStyles();
  useEffect(() => {
    if (allChilds.status === 'idle') {
      dispatch(getAllChild());
    }
  }, [dispatch]);

  return (
    <div className="childs-container ">
      <AddChildButton dispatch={dispatch} />
      <div className="childs-list">
        {allChilds.data.map((child, index) => {
          return (
            <ChildItem
              dispatch={dispatch}
              key={index}
              child={child}
              selected={selectedChild != null && selectedChild == child.user?.id}
              index={selectedChild == child.user?.id ? index : false}
              classes={classes}
            />
          );
        })}
      </div>
    </div>
  );
};

export default ChildList;
