import React from 'react';
import { Formik } from 'formik';
import * as Yup from 'yup';
import Button from '@material-ui/core/Button';
import { TextField, Box, FormHelperText, Typography } from '@material-ui/core';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import useIsMountedRef from '../../hooks/useIsMountedRef';
import { useDispatch } from 'react-redux';
import { addPoints } from '../../slices/points';

const OnlinePayment = ({ id, open, handleClose, data, ...rest }) => {
  const isMountedRef = useIsMountedRef();
  const dispatch = useDispatch();

  return (
    <Dialog
      open={open}
      onClose={(e, reason) => {
        if (reason !== 'backdropClick' && reason !== 'escapeKeyDown') {
          handleClose(id);
        }
      }}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      className="ta-modal add-video-modal"
    >
      <DialogTitle id="alert-dialog-title">
        <span className="label">Paiement en ligne</span>
        <span className="close-btn" onClick={() => handleClose(id)}>
          Fermer
        </span>
      </DialogTitle>
      <DialogContent className="ta-modal-content-scroll">
        <Formik
          initialValues={{
            amount: '',
            paymentMethod: '2',
            reference: '',
          }}
          validationSchema={Yup.object().shape({
            amount: Yup.number()
              .required('Le champ montant est obligatoire')
              .max(5000, 'Le montant doit être inférieur ou égal à 5000'),
          })}
          onSubmit={async (values, { setErrors, setStatus, setSubmitting }) => {
            try {
              let dataToSend = {
                amount: values.amount,
                comment: values.comment,
                paymentMethod: values.paymentMethod,
                reference: values.reference,
              };
              dispatch(addPoints({ values: dataToSend }));
              if (isMountedRef.current) {
                setStatus({ success: true });
                setSubmitting(false);
              }
            } catch (err) {
              console.error(err);
              if (isMountedRef.current) {
                setStatus({ success: false });
                setErrors({ submit: err.message });
                setSubmitting(false);
              }
            }
          }}
        >
          {({
            errors,
            handleBlur,
            handleChange,
            handleSubmit,
            isSubmitting,
            touched,
            values,
            resetForm,
            setFieldValue,
            setTouched,
          }) => (
            <form noValidate onSubmit={handleSubmit} className="ta-form" {...rest}>
              <TextField
                className="input"
                error={Boolean(touched.amount && errors.amount)}
                fullWidth
                helperText={touched.amount && errors.amount}
                margin="dense"
                name="amount"
                onBlur={handleBlur}
                onChange={handleChange}
                type="number"
                value={values.amount}
                variant="outlined"
                size="small"
                placeholder="Montant en dinar"
              />

              <Box mt={2} className="ta-btns-group right">
                <Button
                  disabled={isSubmitting}
                  type="submit"
                  className="ta-btn primary btn-rounded btn-xl"
                >
                  Ajouter des points
                </Button>
              </Box>
            </form>
          )}
        </Formik>
      </DialogContent>
    </Dialog>
  );
};

export default OnlinePayment;
