import { Card, CardContent, IconButton } from '@material-ui/core';
import React from 'react';
import { useDispatch } from 'react-redux';
import { openModal } from '../../slices/modals';

const index = ({ dispatch }) => {
  return (
    <Card className="ta-card bt-card">
      <IconButton className="bt-icon" onClick={() => dispatch(openModal('add-child-modal'))}>
        <CardContent className="bt-content">
          <div className="border">
            <div className="add-icon">+</div>
          </div>
        </CardContent>
      </IconButton>
    </Card>
  );
};

export default index;
