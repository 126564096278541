import React, { useEffect, useState } from 'react';
import InputCard from './InputCard';
import Button from '@material-ui/core/Button';
import VerifiedSms from '../../../src/assets/img/verified-sms.png';
import Verification from '../../../src/assets/img/verification.png';
import { useDispatch, useSelector } from 'react-redux';
import MobileScreenShareIcon from '@material-ui/icons/MobileScreenShare';
import MailOutlineIcon from '@material-ui/icons/MailOutline';
import { confirmCode, resendConfirmCode } from '../../slices/auth';
import Skeleton from '@material-ui/lab/Skeleton';
import WindowDimensions from '../../utilities/windowDimenssions';
import { enqueueSnackbar } from '../../slices/notifier';
import { Slide } from '@material-ui/core';

const Index = () => {
  const [credential, setCredential] = useState();
  const { width } = WindowDimensions();
  const dispatch = useDispatch();
  const { user } = useSelector((state) => state.user);
  const { status, confirmCodeMessage } = useSelector((state) => state.auth);

  const [validationResult, setValidationResult] = useState(confirmCodeMessage);
  const [values, setValues] = useState({});
  const [openSnackBar, setOpenSnackBar] = useState(false);
  const [validationStatus, setValidationStatus] = useState(success);
  const success = status == 'succeeded' ? true : false;

  const maxWidth = width >= 750 ? 750 : width - 50;

  useEffect(() => {
    localStorage.getItem('credential');
    setCredential(
      localStorage.getItem('credential') ? localStorage.getItem('credential') : user?.email
    );
  }, [credential]);

  const handleChange = (e) => {
    setValues((prevState) => ({
      ...prevState,
      [e.target.name]: e.target.value,
    }));
  };

  const resendCode = () => {
    localStorage.setItem('resend', true);
    dispatch(resendConfirmCode());
    setOpenSnackBar(true);
  };
  const handleSubmit = async () => {
    if (!values.code) {
      dispatch(
        enqueueSnackbar({
          message: 'Code invalid',
          options: {
            key: new Date().getTime() + Math.random(),
            variant: 'error',
            anchorOrigin: {
              vertical: 'bottom',
              horizontal: 'center',
            },
            TransitionComponent: Slide,
          },
        })
      );
    }
    // if (+user.verified) {
    //   setValidationStatus(false);
    //   setValidationResult('You are verified');
    //   return setOpenSnackBar(true);
    // }
    await dispatch(confirmCode({ code: values.code }));
  };
  if (!user) {
    return (
      <div className="login-card" style={{ margin: '50px 0px' }}>
        <div className="centered-box" style={{ minWidth: maxWidth, minHeight: 500 }}>
          <Skeleton variant="rect" width={'100%'} height={500} />
        </div>
      </div>
    );
  }
  return (
    <div className="login-card" style={{ margin: '50px 0px' }}>
      <div className="centered-box" style={{ minHeight: 500 }}>
        <span className="title">Dernière étape</span>
        {user && isNaN(credential) && (
          <img
            src={Verification}
            width={width >= 750 ? 350 : maxWidth - 100}
            style={{ margin: 10 }}
          />
        )}
        {user && !isNaN(credential) && (
          <img
            src={VerifiedSms}
            width={width >= 750 ? 350 : maxWidth - 100}
            style={{ margin: 10 }}
          />
        )}
        <InputCard
          value={values.code}
          handleFunction={handleChange}
          placeholder={'Code de verification'}
          typeInput={'text'}
          icon={
            !isNaN(credential) ? (
              <MobileScreenShareIcon className="icon-input" />
            ) : (
              <MailOutlineIcon className="icon-input" />
            )
          }
          name="code"
        />
        <span className="card-description">
          {`Veuillez attendre le message et saisir le code reçu au:  ${
            localStorage.getItem('credential') ? localStorage.getItem('credential') : user?.phone
          } et cliquer sur le bouton Terminer`}
        </span>
        {!localStorage.getItem('resend') && (
          <span className="resend-code" onClick={resendCode}>
            {'renvoyer le code de vérification'}
          </span>
        )}
        <Button
          variant="outlined"
          disableElevation
          onClick={(e) => handleSubmit(e)}
          className="submit-login"
        >
          Terminer
        </Button>
      </div>
    </div>
  );
};
export default Index;
