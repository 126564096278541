import React, { useEffect, useState, useRef, useLayoutEffect } from 'react';
import PropTypes from 'prop-types';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import TablePagination from '@material-ui/core/TablePagination';
import * as dayjs from 'dayjs';
import history from '../../assets/img/icons/history.png';
import { Empty } from '../../components';
import { IconButton } from '@material-ui/core';
import KeyboardArrowUpIcon from '@material-ui/icons/KeyboardArrowUp';
import FormatListBulletedOutlinedIcon from '@material-ui/icons/FormatListBulletedOutlined';
import ExpandRow from './ExpandRow';

import ActionsRow from './ActionsRow';

const Index = ({ cols, rows, bookmark, emptyComponent, noPagination = true, event, className }) => {
  const respoContEl = useRef(null);
  const tableContEl = useRef(null);
  const paginContEl = useRef(null);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [selectedIndex, setSelectedIndex] = useState('');

  const [tableContHeight, setTableContHeight] = useState(0);
  const [paginContHeight, setPaginContHeight] = useState(0);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(event.target.value);
    setPage(0);
  };

  const updateTableDemonsions = (taH, PaH) => {
    respoContEl.current.style.height = `${taH + PaH - 16}px`;
    if (noPagination) {
      return (paginContEl.current.style.marginTop = `${taH}px`);
    } else {
      return `5px`;
    }
  };

  useLayoutEffect(() => {
    setTableContHeight(tableContEl.current.clientHeight);
    setPaginContHeight(noPagination ? paginContEl.current.clientHeight : 0);
    updateTableDemonsions(tableContHeight, paginContHeight);
  });

  const handleSelected = (index) => {
    setSelectedIndex((prev) => {
      return { ...prev, selectedIndex: prev.selectedIndex == index ? null : index };
    });
  };

  return (
    <div className={`table-responsive ${className}`} ref={respoContEl}>
      <TableContainer className="table-container" ref={tableContEl} component={Paper}>
        <Table stickyHeader aria-label="sticky table">
          <TableHead>
            <TableRow>
              {cols &&
                cols.length > 0 &&
                cols.map((col, index) => {
                  return <TableCell key={index}>{col.label}</TableCell>;
                })}
            </TableRow>
          </TableHead>
          <TableBody>
            {rows && rows.length > 0 ? (
              rows.map((row, index) => {
                return (
                  <ExpandRow
                    indexSelected={selectedIndex.selectedIndex === index}
                    expandComponent={
                      <TableCell colSpan="4">
                        <ActionsRow data={event} />
                      </TableCell>
                    }
                    key={index}
                  >
                    {cols &&
                      cols.length > 0 &&
                      cols.map((col, index2) => {
                        return (
                          <TableCell key={index2}>
                            <div>
                              {row[col.accessor] && col.type && col.type === 'date' ? (
                                dayjs(row[col.accessor]).format('DD/MM/YYYY')
                              ) : col.type === 'collapse' ? (
                                <IconButton
                                  onClick={() => {
                                    handleSelected(index);
                                  }}
                                >
                                  {index === selectedIndex.selectedIndex ? (
                                    <KeyboardArrowUpIcon />
                                  ) : (
                                    <FormatListBulletedOutlinedIcon />
                                  )}
                                </IconButton>
                              ) : (
                                row[col.accessor]
                              )}
                            </div>
                          </TableCell>
                        );
                      })}
                  </ExpandRow>
                );
              })
            ) : (
              <TableRow hover role="checkbox" tabIndex={-1}>
                <TableCell colSpan={(cols && cols.length) || 1} align={'center'}>
                  {
                    (emptyComponent = (
                      <Empty label="Pas d'historique." icon={history} className="no-borders" />
                    ))
                  }
                </TableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>
      </TableContainer>
      {noPagination && (
        <TablePagination
          ref={paginContEl}
          rowsPerPageOptions={[10, 25, 100]}
          component="div"
          count={rows?.length || 0}
          rowsPerPage={rowsPerPage || []}
          page={page}
          labelRowsPerPage={<span>Lignes par page:</span>}
          labelDisplayedRows={({ from, to, count }) => {
            return '' + from + '-' + to + ' sur ' + count;
          }}
          onChangePage={handleChangePage}
          onChangeRowsPerPage={handleChangeRowsPerPage}
        />
      )}
    </div>
  );
};

Index.propTypes = {
  page: PropTypes.number,
  setPage: PropTypes.func,
  rowsPerPage: PropTypes.number,
  setRowsPerPage: PropTypes.func,
  handleChangePage: PropTypes.func,
  handleChangeRowsPerPage: PropTypes.func,
};

export default Index;
