import React from 'react';
import Pdf from '../../assets/img/icons/pdf.svg';
import download from '../../assets/img/icons/download.svg';
import { Link } from 'react-router-dom';
const AttachmentsSession = ({ file, calender }) => {
  const { name, url } = calender === true ? file : file?.file;
  return (
    <div className="attachment">
      <div className="attachmentTitle">
        <img src={Pdf} />
        <p className="attachmentName"> {name?.length > 15 ? name?.substr(0, 15) + '...' : name} </p>
      </div>
      <div className="attachmentButton">
        <img src={download} className="downloadIcon" alt="icon" />
        <a href={url} target="_blank">
          <p className="buttonText">Télécharger</p>
        </a>
      </div>
    </div>
  );
};
export default AttachmentsSession;
