import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import Card from '@material-ui/core/Card';
import CardHeader from '@material-ui/core/CardHeader';
import CardContent from '@material-ui/core/CardContent';
import { CardTitle, Empty, ResponsiveTable } from '../../components';
import { useDispatch, useSelector } from 'react-redux';
import { fetchTranferPointsHistory } from '../../slices/points';
import TransactionsResponsiveTable from '../ResponsiveTable/TransactionsResponsiveTable';

const Index = () => {
  const { transfertHistory } = useSelector((state) => state.points);
  const dispatch = useDispatch();
  const headers = [
    { accessor: 'sender_id', label: 'ID Expéditeur' },
    { accessor: 'sender_name', label: 'Expéditeur' },
    { accessor: 'receiver_id', label: 'ID Destinataire' },
    { accessor: 'receiver_name', label: 'Destinataire' },
    { accessor: 'points', label: 'Points' },
    { accessor: 'updated_at', label: 'Envoyé le	', type: 'date' },
    { accessor: 'status', label: 'Statut', type: 'status' },
    { accessor: 'is_confirmed', label: 'Operation Status' },
  ];
  const [data, setData] = useState([]);

  useEffect(() => {
    dispatch(fetchTranferPointsHistory());
  }, []);

  return (
    <Card className="ta-card">
      <CardHeader title={<CardTitle title="Historique des transferts de points" />} />
      <CardContent>
        <div className="child-full">
          <TransactionsResponsiveTable
            rowsCount={transfertHistory?.length}
            className={'home-page'}
            cols={headers}
            rows={transfertHistory}
            emptyComponent={<Empty label="Pas de points." className="no-borders" />}
            //noPagination={false}
          />
        </div>
      </CardContent>
    </Card>
  );
};

Index.propTypes = {
  points_history: PropTypes.array,
  data: PropTypes.array,
  setData: PropTypes.func,
  dispatch: PropTypes.func,
  headers: PropTypes.array,
};

export default Index;
