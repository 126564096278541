import React from 'react';
import PropTypes from 'prop-types';
import constants from '../../utilities/constants';
import { BankAccountsItem } from '../../components';

const data = constants.BANK_ACCOUNTS;

const Index = () => {
  return (
    <div className="ta-responsive">
      <div className="bank-accounts-list">
        {data &&
          data.length > 0 &&
          data.map((item, index) => {
            return <BankAccountsItem data={item} key={index} />;
          })}
      </div>
    </div>
  );
};

Index.propTypes = {
  data: PropTypes.array,
};

export default Index;
