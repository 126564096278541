import { createSlice, createAsyncThunk, current } from '@reduxjs/toolkit';
import Slide from '@material-ui/core/Slide';
import axios from '../utilities/axios';
import { enqueueSnackbar } from './notifier';
import { getAllChild } from './children';
const initialState = {
  document: [],
  status: 'idle',
  error: null,
};

export const addDocuments = createAsyncThunk('document/addDocument', async (query, thunkAPI) => {
  const { values, selectedChild } = query;
  let data;
  try {
    const response = await axios.post(`/parent/${selectedChild}/documents`, values);
    data = await response.data;
    if (response.status === 200) {
      thunkAPI.dispatch(
        enqueueSnackbar({
          message: data.message,
          options: {
            key: new Date().getTime() + Math.random(),
            variant: 'success',
            anchorOrigin: {
              vertical: 'bottom',
              horizontal: 'center',
            },
            TransitionComponent: Slide,
          },
        })
      );
      thunkAPI.dispatch(getAllChild());
      return data.payload;
    }
    throw new Error(response.statusText);
  } catch (err) {
    thunkAPI.dispatch(
      enqueueSnackbar({
        message: err,
        options: {
          key: new Date().getTime() + Math.random(),
          variant: 'error',
          anchorOrigin: {
            vertical: 'bottom',
            horizontal: 'center',
          },
          TransitionComponent: Slide,
        },
      })
    );
    console.log(err);
    return Promise.reject(err.message ? err.message : data?.message);
  }
});

const slice = createSlice({
  name: 'document',
  initialState,
  reducers: {},
  extraReducers: {
    [addDocuments.pending]: (state, action) => {
      state.status = 'loading';
    },
    [addDocuments.fulfilled]: (state, action) => {
      state.status = 'succeeded';
      state.document = action.payload;
    },
    [addDocuments.rejected]: (state, action) => {
      state.status = 'failed';
      state.error = action.payload;
    },
  },
});

export const reducer = slice.reducer;

export default slice;
