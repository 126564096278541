import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import Card from '@material-ui/core/Card';
import CardHeader from '@material-ui/core/CardHeader';
import CardContent from '@material-ui/core/CardContent';
import { CardTitle, MyProgressList, ShowMoreLink } from '../../components';
// import { getSubjectsWithProgress } from '../../redux/slices/subjects';
// import { getSubjectsProgress } from '../../redux/slices/progress';
import { useDispatch, useSelector } from 'react-redux';
import { fetchSubjects } from '../../slices/subjects';
import { fetchSubjectsProgress } from '../../slices/progress';

const Index = () => {
  const { subjects } = useSelector((state) => state.subjects);
  const { selectedChild } = useSelector((state) => state.children);
  const { subjectsProgress } = useSelector((state) => state.progress);
  const dispatch = useDispatch();
  const [Subjects, setSubjects] = useState([]);
  const [itemsNumber, setItemsNumber] = useState(5);
  useEffect(() => {
    if (selectedChild) {
      dispatch(fetchSubjects(selectedChild));
    }
    if (selectedChild) {
      dispatch(fetchSubjectsProgress(selectedChild));
    }
  }, [selectedChild]);
  useEffect(() => {
    if (subjects) {
      setSubjects(subjects.slice(0, itemsNumber));
    }
  }, [subjects, selectedChild]);
  return (
    <Card className="ta-card my-progress-widget">
      <CardHeader title={<CardTitle title="Progression" />} />
      <CardContent>
        <MyProgressList subjects={Subjects} subjectsProgress={subjectsProgress} />
        <ShowMoreLink path="/subjects" />
      </CardContent>
    </Card>
  );
};

Index.propTypes = {
  subjects_progress: PropTypes.array,
  dispatch: PropTypes.func,
  subjects: PropTypes.array,
  setSubjects: PropTypes.func,
};

export default Index;
