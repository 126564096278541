import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import useIsMountedRef from '../../hooks/useIsMountedRef';
import React from 'react';
import { useTheme } from '@material-ui/core/styles';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { useSelector, useDispatch } from 'react-redux';

import { checkQrCode } from '../../slices/points';
import { Avatar, Button } from '@material-ui/core';
import { avatarByGender } from '../../utilities/methods';
import { deleteChild } from '../../slices/children';

const DeleteConfirmation = ({ id, open = true, handleClose, data, ...rest }) => {
  const theme = useTheme();
  // const { name,  last_name, avatar, gender, affiliations } = data?.data?.user;
  const user = data?.data.user;
  const userParentId = data?.data.parentUser;
  const index = data?.index;

  const isMountedRef = useIsMountedRef();
  const { allChilds } = useSelector((state) => state.children);

  const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));
  const dispatch = useDispatch();

  return (
    <Dialog
      open={open}
      onClose={(e, reason) => {
        if (reason !== 'backdropClick' && reason !== 'escapeKeyDown') {
          handleClose(id);
        }
      }}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      className="ta-modal "
    >
      <DialogTitle id="alert-dialog-title">
        <span className="label">Supprimer un enfant</span>
        <span className="close-btn" onClick={() => handleClose(id)}>
          Fermer
        </span>
      </DialogTitle>
      <DialogContent className="ta-form">
        <div className="confirm-text"> Appuyer sur "Confirmer" pour supprimer cet enfant : </div>
        <Avatar
          className="photo"
          alt="Moez fazzi"
          src={avatarByGender(user?.avatar, user?.gender)}
        />
        <div className="child-Info">
          <div className="name">{`${user?.name} ${user?.last_name}`}</div>
        </div>
        <div className="ta-btns-group">
          <Button className="ta-btn btn-rounded btn-lg blue" onClick={() => handleClose(id)}>
            {' '}
            Annuler
          </Button>
          <Button
            className="ta-btn btn-rounded btn-lg danger btn-red-border"
            onClick={() => {
              dispatch(
                deleteChild({
                  Id: userParentId,
                  nextId: allChilds?.data[index == 0 ? index + 1 : index - 1]?.user.id,
                })
              );
              handleClose(id);
            }}
          >
            Confirmer
          </Button>
        </div>
      </DialogContent>
    </Dialog>
  );
};

export default DeleteConfirmation;
