import React, { useState } from 'react';
import { Formik } from 'formik';
import * as Yup from 'yup';
import Button from '@material-ui/core/Button';
import { TextField, Box } from '@material-ui/core';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import useIsMountedRef from '../../hooks/useIsMountedRef';
import {
  confirmTransferPoints,
  resendConfirmTransferPoints,
  transferPoints,
} from '../../slices/points';
import { useDispatch } from 'react-redux';
import { useSelector } from '../../store';
import { Link } from 'react-router-dom';

const ConfirmationTransferPoints = ({ id, open, handleClose, data, ...rest }) => {
  const dispatch = useDispatch();
  const { transferId } = useSelector((state) => state.points);
  const isMountedRef = useIsMountedRef();
  const [openConfirmModal, setOpenConfirmModal] = useState(false);
  const resendCode = () => {
    dispatch(resendConfirmTransferPoints(data?.id || transferId));
  };
  return (
    <Dialog
      open={open}
      onClose={(e, reason) => {
        if (reason !== 'backdropClick' && reason !== 'escapeKeyDown') {
          handleClose(id);
        }
      }}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      className="ta-modal add-video-modal"
    >
      <DialogTitle id="alert-dialog-title">
        <span className="label">Confirmer le transfert de points</span>
        <span className="close-btn" onClick={() => handleClose(id)}>
          Fermer
        </span>
      </DialogTitle>
      <DialogContent className="ta-modal-content-scroll">
        <Formik
          initialValues={{
            confirmationToken: '',
          }}
          validationSchema={Yup.object().shape({
            confirmationToken: Yup.string().required('Le champ code est obligatoire'),
          })}
          onSubmit={async (values, { setErrors, setStatus, setSubmitting }) => {
            try {
              dispatch(confirmTransferPoints(values.confirmationToken));

              if (isMountedRef.current) {
                setStatus({ success: true });
                setSubmitting(false);
                setOpenConfirmModal(true);
              }
            } catch (err) {
              console.error(err);
              if (isMountedRef.current) {
                setStatus({ success: false });
                setErrors({ submit: err.message });
                setSubmitting(false);
              }
            }
          }}
        >
          {({ errors, handleBlur, handleChange, handleSubmit, isSubmitting, touched, values }) => (
            <form noValidate onSubmit={handleSubmit} className="ta-form" {...rest}>
              <TextField
                className="input"
                error={Boolean(touched.confirmationToken && errors.confirmationToken)}
                fullWidth
                helperText={touched.confirmationToken && errors.confirmationToken}
                margin="dense"
                name="confirmationToken"
                onBlur={handleBlur}
                onChange={handleChange}
                type="text"
                value={values.confirmationToken}
                variant="outlined"
                size="small"
                placeholder="Code de confirmation"
              />
              <Box mt={2} className="ta-btns-group right">
                <Button
                  disabled={isSubmitting}
                  type="submit"
                  className="ta-btn primary btn-rounded btn-xl"
                >
                  Valider
                </Button>
                <div style={{ color: '#0d0e0f', fontWeight: 'bold' }} onClick={resendCode}>
                  <Link
                    style={{
                      color: '#2ba7df',
                      textDecoration: 'underline',
                      textUnderline: '#2db3f3',
                      fontWeight: 'bold',
                      bottom: '30px',
                    }}
                    to="#"
                  >
                    Resend Code
                  </Link>
                </div>
              </Box>
            </form>
          )}
        </Formik>
      </DialogContent>
    </Dialog>
  );
};

export default ConfirmationTransferPoints;
