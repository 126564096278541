import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import Card from '@material-ui/core/Card';
import CardHeader from '@material-ui/core/CardHeader';
import CardContent from '@material-ui/core/CardContent';
import {
  CardTitle,
  BankAccountsList,
  WalletBalance,
  VariantButton,
  PointsTransferButton,
} from '../../components';
import { useDispatch, useSelector } from 'react-redux';
import { openModal } from '../../slices/modals';
import { IconButton } from '@material-ui/core';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';

//import { me } from '../../redux/slices/user';

const Index = ({ external }) => {
  const dispatch = useDispatch();

  const { user } = useSelector((state) => state.user);
  return (
    <Card className={`ta-card wallet-details ${external ? 'external' : ''}`}>
      <div className="return-bt">
        <IconButton onClick={() => window.location.replace('/')}>
          {' '}
          <ArrowBackIcon style={{ width: '25px', height: '25px' }} />
        </IconButton>
        <CardHeader title={<CardTitle title="Porte monnaie" />} />
      </div>

      <CardContent>
        <div className="child-left">
          <BankAccountsList />
        </div>
        <div className="child-right">
          {<WalletBalance points={user?.points ? user?.points : 0} external={external} />}
          {!!external && (
            <>
              <Link to="/porte-monnaie">
                <VariantButton label="Charger le compte de votre enfant" className="blue" />
              </Link>
            </>
          )}
          {!external && (
            <PointsTransferButton
              title={'Transférer des points'}
              onClick={() => dispatch(openModal('point-transfer-modal'))}
            />
          )}
        </div>
      </CardContent>
    </Card>
  );
};

Index.propTypes = {
  points: PropTypes.string,
  setPoints: PropTypes.func,
  external: PropTypes.bool,
  dispatch: PropTypes.func,
  informations: PropTypes.object,
};

export default Index;
