import React, { useState } from 'react';
import CardContent from '@material-ui/core/CardContent';
import Typography from '@material-ui/core/Typography';
import { Progress } from '../..';
import PropTypes from 'prop-types';

const Index = (props) => {
  const [instructorTab, setInstroctorTab] = useState();
  const { title, instructor, numberVideos, progress, index, leftSize, progressValue } = props;

  return (
    <div
      className="tab-item"
      onClick={() => {
        window.location.href = '/courses';
      }}
    >
      <CardContent className="CardContent">
        <div className="spaceTitle">
          <Typography className="index">{index < 10 ? `0${index}` : index} &nbsp;&nbsp;</Typography>
          <Typography className="title-item">{title}</Typography>
        </div>
        {progress === true && (
          <div>
            <div className="details">
              <Typography className="infos" display="inline" style={{ marginLeft: leftSize }}>
                Par: {instructor}
              </Typography>
              <Typography
                className="infos"
                color="textSecondary"
                variant="body2"
                component="p"
                display="inline"
              >
                {numberVideos} videos
              </Typography>
            </div>
            <div className="details">
              <Progress
                value={progressValue}
                color={'#FE726E'}
                rightSize={60}
                leftSize={leftSize}
              />
            </div>
          </div>
        )}
      </CardContent>
    </div>
  );
};

Index.propTypes = {
  title: PropTypes.any,
  instructor: PropTypes.any,
  numberVideos: PropTypes.any,
  index: PropTypes.any,
  leftSize: PropTypes.any,
  progressValue: PropTypes.any,
  progress: PropTypes.bool,
};

export default Index;
