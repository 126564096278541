import React from 'react';
import PropTypes from 'prop-types';
import AccessTimeIcon from '@material-ui/icons/AccessTime';
import ReactHtmlParser from 'html-react-parser';

const Index = ({ event }) => {
  const openSessions = () => {
    window.location = 'travail-à-faire';
  };
  return (
    <>
      {event && (
        <div className="today-events-item" onClick={openSessions}>
          <span className="label">
            <AccessTimeIcon fontSize="small" />
            {`Aujourd'hui - ${event.start_time}`}
          </span>
          <span className="author">{`Matière: ${event.subject}`}</span>
          {event.description && (
            <span
              className="description"
              dangerouslySetInnerHTML={{
                __html: `${ReactHtmlParser(event.description)}`,
              }}
            ></span>
          )}
          <span className="author">{`Présenté par ${event.teacher}`}</span>
        </div>
      )}
    </>
  );
};

Index.propTypes = {
  event: PropTypes.object,
};

export default Index;
