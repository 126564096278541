import { CircularProgress, Typography } from '@material-ui/core';
import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { fetchOneEvent } from '../../slices/events';
import AttachmentsSession from '../AttachementsSession';

const ActionsRow = ({ data }) => {
  const { event, status } = useSelector((state) => state.events);

  return (
    <>
      {status === 'succeeded' ? (
        <div className="ta-column-expand">
          <div className="sessionInfo-expand">
            <Typography variant="h7" className="infoSpan">
              Instructeur :
              <Typography variant="h7" className="infoValue">
                {` ${data?.content?.instructor?.name} ${data?.content?.instructor?.last_name}`}
              </Typography>
            </Typography>
            <Typography variant="h7" className="infoSpan">
              Niveaux :
              {data?.content?.content_student_levels.map((level) => (
                <Typography variant="h7" className="levels">
                  {level.student_level.name}
                </Typography>
              ))}
            </Typography>
          </div>
          <div className="sessionDocuments-expand">
            {data?.content?.content_files?.length === true && (
              <Typography variant="h7" className="sessionDescription">
                Travail à faire :
              </Typography>
            )}
            {data?.content?.content_files?.map((file) => {
              return <AttachmentsSession calender={false} file={file} />;
            })}
          </div>
        </div>
      ) : (
        <div className="loading">
          {' '}
          <CircularProgress />
        </div>
      )}
    </>
  );
};

export default ActionsRow;
